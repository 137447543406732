.product-icon {
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2px;

  // Special styling for some of the icons:
  &[style*="/logo"] {
    background-color: $darkBackgroundColor;
    background-size: 70%;
  }
  &[style*="/videos-and-articles"] {
    background-size: cover;
  }
  &[style*="/memomaps"] {
    background-size: 60%;
  }
}