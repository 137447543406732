.productbox-container {
  position: absolute;
  top: $headerHight;
  z-index: 1;

  width: 100%;
  clear: both;

  display: none;
  &.expanded {
    display: block;
  }
}
.productbox {
  width: 600px;
  margin-left: auto;
  margin-right: 20px;

  display: flex;

  background-color: white;
  border: 1px solid $lightBackgroundColor;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

}
.productbox-left {
  display: flex;
  flex-direction: column;
  padding: 2em;
  width: 35%;
  background-color: $lightBackgroundColor;
  .title {
    font-weight: 600;
    font-size: 120%;
    color: $whitebgTextColorA;
  }
  .subtitle {
    color: $whitebgTextColorB;
    margin-top: 1em;
    font-size: 90%;
    line-height: 1.5em;
  }
  .button {
    @include standard-green-button();
    text-align: center;
    margin-top: 1.5em;
  }
}
.productbox-right {
  display: flex;
  align-items: center;

  flex: 1;
  padding: 2em;

  ul {
    width: 100%; // Strange ie10 fix, to prevent ul from being more than 100% wide
    display: flex;
    flex-wrap: wrap;

    margin: -1em 0px;
  }
  li {
    width: percentage(1/3);
    //height: 6em;
    text-align: center;
    margin: 1em 0px;
  }
  .icon {
    display: inline-block;
    width: 4em;
    height: 4em;
    background-color: $lightBackgroundColor;
    border: 1px solid darken($lightBackgroundColor, 2%);
    margin-bottom: 0.5em;
  }
  .title {
    font-size: 80%;
    color: $whitebgTextColorB;
  }
}