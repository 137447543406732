@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');

@import "_shared.scss";

$h1-title: 3.8em; $h1-title-vw:8vw;;
$h2-title: 2.5em; $h2-title-vw:7.5vw;
$h2B-title: 2.3em; $h2-title-vw:7vw;
$h3-title: 2em; $h3-title-vw:6vw;
$h4-title: 1.5em; $h4-title-vw:5vw;
$h5-title: 1.3em; $h4-title-vw:4.7vw;
$sub-text: 1.2em; $sub-text-vw:4.3vw;
$paragraph: 1.1em; $paragraph-vw:3.4vw;
$smalltext: 0.95em; $smalltext-vw:2.6vw;
$uppertitle: 0.8em; $uppertitle-vw:2.7vw;
$title-font: 'Raleway', sans-serif;
$text-font:  Source Sans Pro; 
$camelcase-spacing:  0.02em;
$uppercase-spacing:  0.1em;
$color-white: #D6D4EF;
$color-gray:  #A09DAC;
$color-lightblue:#9eaeee;
$line-height:1.55em;

//spacers for layout//
$spacing-4px:4px;
$spacing-8px:8px;
$spacing-12px:12px;
$spacing-16px:16px;
$spacing-20px:20px;
$spacing-24px:24px;

  .h1 {
    color:$color-white;
    font-size: $h1-title;
    font-family:$title-font;
    letter-spacing: $camelcase-spacing;
    font-weight: 600;
  }
  
  .h2 {
    color:$color-white;
    font-size: $h2-title;
    font-family:$title-font;
    letter-spacing: $camelcase-spacing;
    font-weight: 600;
  }
  
       
  .h3 {
    font-size: $h3-title;
    color: $color-white;
    letter-spacing:$camelcase-spacing;
    font-family: $text-font;
    font-weight: 500;
  }
  
  .h4 {
    font-size: $h4-title;
    color: $color-white;
    letter-spacing:$camelcase-spacing;
    font-family: $text-font;
    font-weight: 500;
  }
  
  .subtitle {
    color: $color-gray;
    font-family:$text-font;
    font-size: $sub-text;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    line-height: 1.6em;
  }
  
  .paragraph {
    color:$color-gray;
    font-family: $text-font;
    font-size: $paragraph;
    letter-spacing:$camelcase-spacing;
  }
  
  .uppertitle {
    color: $color-lightblue;
    letter-spacing:$uppercase-spacing;
    font-family: $text-font;
    font-size: $uppertitle;
    font-weight: 600;
  }
  
  @media only screen and (max-width: 600px) {
  .h1 {font-size: $h1-title-vw;}
  .h2 {font-size: $h2-title-vw;}
  .h3 {font-size: $h3-title-vw;}
  .h4 {font-size: $h4-title-vw;}
  .subtitle {font-size: $sub-text-vw;}
  .paragraph {font-size: $paragraph-vw;}
  .uppertitle {font-size: $uppertitle-vw;}
  }


#section-header {
    background-color: transparent;
    z-index: 10000;

    .mainSection {
        width: 100vw;
        background-color: #090936;
        position: fixed;
        top: 0;
        left: 0;

        .languagemenu {
            background-color: transparent;
        }
        .languagemenu-icon {
            display: none;
        }
        .languagemenu-icon::after {
            border-top: 5px solid #c6c3d3;
        }
        .business {
            color: #c6c3d3;
        }
        .school {
            display: none;
            // color: #c6c3d3;
        }
        .about {
            color: #c6c3d3;
        }
        .products {
            display: none;
            // color: #c6c3d3;
            // &:hover {
            //     color: #c6c3d3;
            // }
        }
        .products.active {
            color: #c6c3d3;
        }
        a {
            color: #c6c3d3;
        }
        .signup {
            background-color: #5a63e4;
            &:hover {
              background-color: #3e46bd;
            }
        }
    }
    #pushdownmenu-btn {
        .navicon:after {
            background: #c6c3d3;
        }
        .navicon {
            background: #c6c3d3;
        }
        .navicon:before {
            background: #c6c3d3;
        }
    }
    .pushdownMenu .menu-content {
        background-color: #181752;
    }
    #pushdownmenu-btn::after {
        border-bottom: 14px solid #181752;
        border-bottom-width: 0px;
    }
    #section-header #pushdownmenu-btn.active::after {
        border-bottom-width: 14px;
    }
    .logo::after {
        background-image: hashed-url("images/_shared/memolife-white.svg");
    }

}




html {
    overflow-x: hidden;
}
::-webkit-scrollbar {
    background: transparent; 
}
::-webkit-scrollbar-thumb {
    background-color: #0e0c38;
}
body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: #110E44;
    background-color: #0e0c3d;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    overflow-x: hidden;
    overflow-y: hidden;
}
.curvedBackground1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #090936;
    -webkit-clip-path: ellipse(150% 100% at 50% 0%);
    clip-path: ellipse(150% 100% at 50% 0%);
    width: 100vw;
    padding-bottom: 6em;
    padding-top: 9em;
}
.introDesktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70em;
}
.introMobile {
    display: none;
}
.introAnimContainer {
    height: 25em;
    display: flex;
    align-items: center;
}
.canvasMemomanLiftoff {
    height: 400px;
}
.headerButtonIntroContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 100vw;
    height: 20.4em;
    z-index: 1000;
}
.headerIntro1, .headerIntro2 {
    color:$color-white;
    font-size: 3.2em;
    font-family:$title-font;
    letter-spacing: $camelcase-spacing;
    font-weight: 600;
    margin: 0;
    width: 12.8em;
    text-align: start;
}
.subheaderIntro {
    color: $color-gray;
    font-family:$text-font;
    font-size: $sub-text;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    line-height: 1.6em;
    padding: 0em;
    width: 28.5em;
    margin-bottom: 0;
    text-align: start;
}
.getStartedButton {
    color: $color-white;
    letter-spacing:$camelcase-spacing;
    font-family: $text-font;
    background-color: #4452FE; 
    width:auto;
    margin-top: 3em;
    padding:1em 2em 1em 2em;
    font-size: 1em;
    font-weight: 600;
    border-radius:50px; 
    cursor: pointer;
    transition: all .2s ease-in;
    z-index: 10000;
}
.getStartedButton:hover {
    position:relative;
    transform: translateY(-4px);
    background:#2D3CF5;
    letter-spacing:0.4px;
}
.imageIntro {
    position: absolute;
    width: 70em;
    padding-top: 5em;
    -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}

.introMediaRow {
    display: flex;
    justify-content: center;
    opacity: 0.6;
}
.introMediaLogoImage {
    padding: 1em;
    height: 3.14em;
    width: auto;
    margin: 0.3em;
    margin-top: 0.5em;
    // background-color: #1b174e62;
    border-radius: 0.5em;
}


.missions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* min-height: 80em; */
    margin-top: 8em;
    width: 70em;
    z-index: 1000;
}
.missionsContainers {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
    /* padding: 2em; */
}
.missionContainer1, .missionContainer2, .missionContainer3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1b174e62;
    margin: 1em;
    margin-bottom: 1em;
    margin-left: 0em;
    padding: 1.5em;
    padding-top: 0.5em;
    width: 100%;
    border-radius: 0.6em;
    // cursor: pointer;
    // border: 2px solid rgba(111, 111, 255, 0.544);
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}
.missionContainer3 {
    margin-right: 0em;
}
.missionImage {
    width: 11em;
    height: 12em;
    margin-top: 2em;
    margin-bottom: 2em;
}
.missionTitle {
    font-size: $h5-title;
    color: $color-white;
    letter-spacing:$camelcase-spacing;
    font-family: $text-font;
    margin-bottom: 12px;
    margin-top: 0;
}
.missionSubtitle {
    color:$color-gray;
    font-family: $text-font;
    font-size: $paragraph;
    letter-spacing:$camelcase-spacing;
    width: 15em;
    text-align: center;
    margin-top: 0;
}

.funBrainhacksContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8em;
    width: 70em;
    
}
.brainHacksHeader {
    color:$color-white;
    font-size: 2.5em;
    font-family:$title-font;
    letter-spacing: $camelcase-spacing;
    line-height: 1.1em;
    font-weight: 600;
    margin: 0;
}
.brainHackSubheader {
    display: flex;
    justify-content: center;
    color: $color-gray;
    font-family:$text-font;
    font-size: $sub-text;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    width: 30em;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.brainHacksListContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-items: start;
    margin-top: 2em;
}
.brainHackContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2em;
    
}
.brainHackImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10em;
    width: 13em;
    margin-bottom: 2em;
    /* background-color: #1b174e62; */
}
.brainHackImage {
    width: 23em;
    height: auto;
}
.brainHackTitle {
    font-size: 1.5em;
    color: $color-white;
    letter-spacing:$camelcase-spacing;
    font-family: $text-font;
    margin: 0;
}

.brainHackSubtitle {
    color: $color-gray;
    font-family:$text-font;
    font-size: .9em;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    margin-top: 0.6em;
}


.universe {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* min-height: 80em; */
    margin-top: 6em;
    width: 70em;
    z-index: 1000;
}
.headerUniverse1, .headerUniverse2 {
    display: flex;
    justify-content: center;
    color:$color-white;
    font-size: 2.5em;
    font-family:$title-font;
    letter-spacing: $camelcase-spacing;
    font-weight: 600;
    margin: 0;
    width: 20em;
}
.subheaderUniverse {
    display: none;
    justify-content: center;
    color: $color-gray;
    font-family:$text-font;
    font-size: $sub-text;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0.4em;
}
.lifeskillTextContainer {
    position: absolute;
    display: flex;
    align-items: center;
    width: 20em;
    margin-bottom: 2em;
    opacity: 0;
    transition: opacity 1s ease-in;
} 
.lifeksillText {
    text-align: left;
    color: $color-gray;
    font-family:$text-font;
    font-size: $paragraph;
    letter-spacing:$camelcase-spacing;
}
.riveContainer {
    height: 22em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
.canvas {
    height: 430px;
    // width: 800px;
}
.popularTabImage {
    display: flex;
    width: 55em;
    height: auto;
    margin-bottom: 0;
}
.popularTabImageMobile {
    display: none;
}
.categoryTitlesContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    // width: 40em;
    font-family: "HelveticaNeue", sans-serif;
}
#categoryPopular, #categoryBrain, #categoryKnowledge, #categoryMind, #categoryBody, #categorySoul, #categoryCareer, #categoryLeisure {
    color: $color-gray;
    font-family:$text-font;
    font-size: $sub-text;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    line-height: 1.3em;
    margin: 1em;
    opacity: .6;
    cursor: pointer;
    transition: all .2s ease-in;
    border-radius: 6em;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1000;
}
.categoryTitle:hover {
    background:#646efc11;
    color: white;
    opacity: 0.7;
    padding: 0.5em 1em;
}


.galaxyImageContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    /* height: 23em; */
}
.galaxyImage {
    padding: 1em;
    /* height: 23em; */
    /* background-color: burlywood; */
}
.imageLifeskillNumber {
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
    font-size: 2.3em;
    font-family: "HelveticaNeue", sans-serif;
    opacity: 0;
}
.lifeskillsContainer {
    display: flex;
    justify-content: start;
    flex-flow: row wrap;
    align-items: center;
    /* height: 16em; */
    width: 70em;
}
.lifeskillsTitle {
    position: absolute;
    align-self: end;
    font-family: 'Source Sans Pro';
    text-align: center;
    font-size: 1.3em;
    color: #9a9dff;
    opacity: 1; 
    margin-bottom: 1.5em;
    // padding-left: 1em;
}

.lifeskillsGrid {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    width: 70em;
}
.lifeskillPopular, .lifeskillBrain, .lifeskillKnowledge, .lifeskillMind, .lifeskillBody, .lifeskillSoul, .lifeskillCareer, .lifeskillLeisure {
    display: flex; 
    justify-content: start; 
    cursor: pointer; 
    align-items: center; 
    padding: 0.3em 1em; 
    background-color: #1b174eb3;  
    height: 4em; 
    width: 14.37em; 
    font-size: 1em; 
    color: white; 
    margin: 0.5em; 
    border-radius: 0.3em;
    border: 1px solid transparent;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 1000;
}
.lifeskillSunImage {
    position: absolute;
    width: 3.5em;
    height: 3.5em;
}
.lifeskillNum {
    padding-top: 0.1em;
    padding-left: 1.3em; 
    padding-right: 1.5em; 
    border-radius: 2em; 
    margin-right: 1em;
    font-size: 0.9em;
    font-family: "HelveticaNeue", sans-serif;
    color: rgba(0, 0, 0, 0.452);
    z-index: 1;
}
.lifeskillName {
    text-align: start;
    font-size: 0.9em;
    font-family: "HelveticaNeue", sans-serif;
    color: white;
}
.lifeskillsWorkInProgressTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70em;
    font-size: 0.8em;
    font-family: "HelveticaNeue", sans-serif;
    color: white;
    opacity: 0.3;
    margin-top: 2em;
}

.media {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* min-height: 80em; */
    margin-top: 6em;
    width: 70em;
    z-index: 1000;
}
.mediaContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10em;
}
.mediaHeader {
    font-size: 2em;
    color:#D6D4EF;
    font-family: 'Raleway', sans-serif;
    line-height: 1.4em;
    letter-spacing: 0.05em;
    opacity: 0.9;
}

.mediaTabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 5em;
    margin-top: 0.4em;
}
.mediaThumbnailImage {
    width: 100%;
    height: auto;
}
.mediaUsersTab, .mediaCompaniesTab, .mediaMediaTab {
    color: $color-gray;
    font-family:$text-font;
    font-size: $sub-text;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    line-height: 1.6em;
    margin: 1em;
    opacity: .4;
    cursor: pointer;
    transition: all .2s ease-in;
    border-radius: 6em;
    user-select: none;
}
.mediaUsersTab:hover, .mediaCompaniesTab:hover, .mediaMediaTab:hover {
    transform: translateY(-4px);
    background:#646efc11;
    color: white;
    opacity: 0.7;
    padding: 0.5em 1em;
    letter-spacing:0.4px;
}


.mediaArticlesUsers, .mediaArticlesCompanies, .mediaArticlesMedia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 4em;
}
.mediaArticles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1em;
}
.mediaArticlesUsersTeaser {
    color:#9eaeee;
    font-family:helvetica;
    letter-spacing: 0.05em;
    font-size: 0.95em;
    line-height: 1.35em;
    text-align: start;
    width: 34em;
}
.mediaArticles1 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
}
.mediaArticles2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    margin-top: 6em;
}
.mediaArticle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    width: 20em;
    border-radius: 1em;
    padding: 2em;
    padding-bottom: 3em;
    margin: 0.5em;
    transition: all 0.2s ease-in;
    background-color: #1c185c;
}
.mediaArticle:hover {
    background-color: #262174;
    cursor: pointer;
}
.mediaVideo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: start;
    width: 20em;
    border-radius: 1em;
    padding: 1em;
    margin: 0.5em;
    transition: all 0.2s ease-in;
    background-color: #1c185c;
}
.mediaVideo:hover {
    background-color: #262174;
    cursor: pointer;
}
.mediaTitle {
    color:$color-gray;
    font-family: $text-font;
    font-size: $paragraph;
    letter-spacing:$camelcase-spacing;
    margin: 0;
    margin-top: 1em;
}
.mediaText {
    color: white;
    font-family: "HelveticaNeue", sans-serif;
    font-size: 1em;
    opacity: 0.4;
    margin-top: 0.5em;
    line-height: 1.4em;
}
.mediaLogoNameContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1.2em;
}
.mediaLogo {
    width: 1.2em;
    height: 1.2em;
}
.mediaName {
    color: white;
    font-family: "HelveticaNeue", sans-serif;
    font-size: 0.6em;
    opacity: 0.4;
    margin-left: 1em;
}
.youtube-video-frame {
    width: 40em; 
    height: 30em;
}

.youtube-video-frame-media {
    width: 100%;
    height: 15em;
}

.youtube-video-frame-end {
    width: 30em; 
    height: 20em;
    margin-top: 1em
}

.mediaImages {
    padding-top: 2em;
    width: 66em;
}
.mediaLogoImage {
    padding: 2em;
    height: 7em;
    width: auto;
    margin: 0.3em;
    background-color: #1b174e62;
    border-radius: 1em;
}


.headerMedia {
    color:$color-white;
    font-size: 2.5em;
    font-family:$title-font;
    letter-spacing: $camelcase-spacing;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.3em;
    width: 16em;
    z-index: 10000;
}
.subheaderMedia {
    color: $color-gray;
    font-family:$text-font;
    font-size: $sub-text;
    letter-spacing:$camelcase-spacing;
    font-weight:500;
    line-height: 1.6em;
    text-align: center;
    width: 42em;
    margin-bottom: 0.5em;
    z-index: 10000;
}

.usersFeedbackContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    height: 13em;
    width: 50em;
}
.userFeedbackContainer {
    height: 10em;
    width: 20em;
    border: 1px solid aqua;
}



.conquerUniverse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5em;
    width: 70em;
}

.conquerUniverseContainers {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: row wrap;
    margin-bottom: 1em;
    margin-top: 3em;
}
.conquerUniverseContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 21em;
    height: 17em;
}
.conquerUniverseimageContainer {
    display: flex;
    align-items: center;
    height: 7em;
    margin-bottom: 1em;
}
.conquerUniverseimage {
    height: 20em;
    width: auto;
}

.memolifeFamily {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10em;
    margin-bottom: 10em;
    width: 70em;
    color: rgba(255, 255, 255, 0.219);
}
.memolifeFamilyMobile {
    display: none;
}
.memolifeFamilyHeaderImagesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.memolifeFamilyHeaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;
    text-align: center;
}

.contactButton {
    color: $color-white;
    letter-spacing:$camelcase-spacing;
    font-family: $text-font;
    background-color: #4452FE; 
    width:auto;
    margin-top: 1em;
    margin-bottom: 0.5em;
    padding:1em 2em 1em 2em;
    font-size: 1em;
    font-weight: 600;
    border-radius:50px; 
    cursor: pointer;
    transition: all .2s ease-in;
    z-index: 10000;
}
.contactButton:hover {
    position:relative;
    transform: translateY(-4px);
    background:#2D3CF5;
    letter-spacing:0.4px;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70em;
    padding-bottom: 3em;
}
.footerValueProp {
    color:$color-gray;
    font-family: $text-font;
    font-size: 1em;
    letter-spacing:$camelcase-spacing;
    text-align: center;
    margin-bottom: 0;
    width: 22em;
}
.footerCompanyName {
    color:$color-gray;
    font-family: $text-font;
    font-size: 1em;
    letter-spacing:$camelcase-spacing;
    opacity: 0.6;
    margin-bottom: 0;
}
.footerLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3em;
}
.footerLink {
    padding: 1.5em;
    padding-bottom: 0;
    padding-top: 0;
    color:$color-gray;
    font-family: $text-font;
    font-size: $smalltext;
    letter-spacing:$camelcase-spacing;
    text-decoration: none;
}
.footerIcons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footerIcon1 {
    display: flex;
    justify-content: center;
    margin-left: 1.2em;
    margin-right: 1.2em;
    height: 2.5em;
    width: 2.5em;
    border-radius: 2em;
    background-color: #1F1B60;
    padding: 0.6em;
    cursor: pointer;
}
.footerIcon2 {
    display: flex;
    justify-content: center;
    margin-left: 1.2em;
    margin-right: 1.2em;
    height: 2.5em;
    width: 2.5em;
    border-radius: 2em;
    background-color: #1F1B60;
    padding: 0.7em;
    cursor: pointer;
}


@media only screen and (max-width: 600px) {

    #section-header {
        .mainSection {
            background-color: #0e0c3d;
            position: relative;
        }
        .logo {
            padding: 20px 27px;
        }
        li a {
            color:#bfbccc;
            font-family: $text-font;
            font-size: $paragraph;
            letter-spacing:$camelcase-spacing;
        }
    }

    .introDesktop {
        display: none;
    }
    .introMobile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 150vw;
        width: 90vw;
    }
    .canvasMemomanLiftoffContainer {
        height: 92vw;
    }
    .canvasMemomanLiftoff {
        height: 100vw;
    }
    .headerButtonIntroContainer {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        text-align: center;
        width: 100vw;
        padding-left: 0;
        padding-top: 6vw;
        height: 57vw;
    }
        .headerIntro1, .headerIntro2  {
            margin: 0;
            font-size: 7vw;
            width: auto;
            text-align: center;
        }
        .subheaderIntro {
            font-size: 4vw;
            padding: 0;
            width: 90vw;
            text-align: center;
        }
        .getStartedButton {
            margin-top: 5vw;
            font-size: 3.3vw;
            border-radius: 20vw;
            width: auto;
            padding: 3.5vw 6vw 3.5vw 6vw;
        }
        .imageIntro {
            position: absolute;
            top: 0;
            right: 0;
            /* width: 180vw; */
            -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
        }
        .curvedBackground1 {
            display: none;
            background-color: transparent;
            clip-path: none;
            padding-bottom: 14vw;
        }

        
        .introMediaRow {
            justify-content: start;
            overflow-y: hidden;
            -ms-overflow-style: none;  
            scrollbar-width: none;
            width: 95vw;
            margin-bottom: 10vw;
        }
        ::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
        .introMediaLogoImage {
            padding: 3.5vw;
            height: 10.5vw;
            width: auto;
            margin: 1vw;
            margin-top: 0;
            margin-left: 0;
            // margin-bottom: 5vw;
            // background-color: #1b174e62;
        }
        

        .missions {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100vw;
            z-index: 1000;
            margin-top: 20vw;
        }
        .headerMissions {
            font-size: 6vw;
        }
        .subheaderMissions {
            font-size: 3.7vw;
        }
        .missionsContainers {
            justify-content: start;
            overflow-y: auto;
            -ms-overflow-style: none;  
            scrollbar-width: none;
            width: 100vw;
            margin: 6vw 0vw;
            margin-left: 6vw;
            padding: 4vw 0vw;
        }
        .missionsContainers::-webkit-scrollbar {
           display: none;
        }
        .missionContainer1, .missionContainer2, .missionContainer3 {
            margin: 2vw;
            padding: 5vw;
            padding-left: 7vw;
            width: 80vw;
            border-radius: 2vw;
        }
        .missionImage {
            height: 45vw;
            margin-top: 5vw;
            margin-bottom: 5vw;
        }
        .missionTitle {
            font-size: 5vw;
            margin-bottom: 2vw;
            margin-top: 2vw;
        }
        .missionSubtitle {
            font-size: 3.7vw;
            // width: 60vw;
        }
        .bottomTextMissions {
            width: 90vw;
        }
        
        .funBrainhacksContainer {
            margin-top: 20vw;
        }
        .brainHacksHeader {
            font-size: 7.5vw;
            width: 95vw;
            text-align: center;
        }
        .brainHackSubheader {
            width: 80vw;
            font-size: 4vw;
        }
        .brainHacksListContainer {
            flex-direction: column;
            flex-wrap: wrap;
            flex-direction: row;  
            margin-top: 3vw;
            width: 100vw;
        }
        .brainHackContainer {
            padding: 6vw;
            padding-bottom: 0;
            padding-top: 0;
            width: 47vw;
        }
        .brainHackImageContainer {
            height: 46vw;
            width: 50vw;
            margin-bottom: 0vw;
        }
        .brainHackImage {
            width: 80vw;
            height: auto;
        }
        .brainHackTitle {
            font-size: 4vw;
            width: 42vw;
            font-weight: 600;
        }
        .brainHackSubtitle {
            font-size: 3.7vw;
            letter-spacing: 0.02em;
            line-height: 1.3em;
            width: 44vw;
        }

        .universe {
            margin-top: 0;
            width: 90vw;
        }
        .lifeskillsTitle {
            font-size: 4.2vw;
            margin-bottom: 5vw;
            width: 85vw;
            padding-left: 0;
        }
        .lifeskillTextContainer {
            width: 80vw;
            margin-bottom: 8vw;
        } 
        .lifeksillText {
            font-size: 4vw;
            padding-left: 4vw;
        }
        .riveContainer {
            height: 60vw;
        }
        .canvas {
            width: 125vw;
            height: auto;
            padding-bottom: 15vw;
            // padding-left: 5vw;
            margin-right: 0;
        }
        .popularTabImage {
            display: none;
        }
        .popularTabImageMobile {
            display: flex;
            width: 92vw;
            height: auto;
            margin-bottom: 8vw;
        }
        .headerUniverse1, .headerUniverse2  {
            font-size: 7vw;
            margin: 0;
            width: 95vw;
        }
        .subheaderUniverse {
            font-size: 4vw;
        }
        .categoryTitlesContainer {
            /* flex-flow: row wrap; */
            overflow-y: hidden;
            -ms-overflow-style: none;  
            scrollbar-width: none;
            display: flex;
            justify-content: start;
            width: 90vw;
            height: 20vw;
            padding-bottom: 3vw;
        }
        .categoryTitlesContainer::-webkit-scrollbar {
           display: none;
        }
        .categoryTitle {
            padding: 1.5vw;
        }
        .galaxyImage {
            padding: 4vw;
            margin: 0vw;
            height: 60vw;
            /* height: 90vw; */
        }
        .imageLifeskillNumber {
            padding-top: 0.7vw;
            padding-left: 3vw;
            padding-right: 2vw;
            margin-right: 4vw;
            font-size: 3.2vw;
        }
        .lifeskillsContainer {
            width: 100vw;
        }
        .lifeskillsGrid {
            /* height: 70vw; */
            
            width: 100vw;
            justify-content: center;
        }
        #categoryPopular, #categoryBrain, #categoryKnowledge, #categoryMind, #categoryBody, #categorySoul, #categoryCareer, #categoryLeisure {
            width: 90vw;
            font-size: 3.7vw;
            margin: 3.2vw;
            padding: 0;
        }
        .lifeskillPopular, .lifeskillBrain, .lifeskillKnowledge, .lifeskillMind, .lifeskillBody, .lifeskillSoul, .lifeskillCareer, .lifeskillLeisure {
            padding: 1vw 3vw; 
            height: 15vw; 
            width: 47vw;
            font-size: 3.7vw; 
            margin: 1.2vw; 
            border-radius: 1vw;
            z-index: 1000;
        }
        .lifeskillSunImage {
            position: absolute;
            width: 10.5vw;
            height: 13vw;
        }
        .lifeskillNum {
            padding-top: 0.7vw;
            padding-left: 3.2vw;
            padding-right: 4vw;
            margin-right: 2vw;
            font-size: 3.7vw;
        }
        .lifeskillName {
            font-size:3vw;
        }
        .lifeskillsWorkInProgressTitle {
            width: 90vw;
            font-size: 4vw;
            margin-top: 7vw;
        }
        .media {
            margin-top: 10vw;
            width: 90vw;
        }
        .memberProfession {
            font-size: 3.3vw;
            margin-top: 1.5vw;
        }

        .mediaContainer{
            width: 90vw;
            margin-top: 20vw;
        }
        .mediaHeader {
            font-size: 6vw;
            text-align: center;
            margin-top: 8vw;
            margin-bottom: 5vw;
        }
        .subheaderMedia {
            width: 82vw;
            font-size: 4vw;
            padding-bottom: 4vw;
        }
        .mediaTabs {
            height: 15vw;
            margin-top: 0vw;
            margin-bottom: 0vw;
        }
        .mediaUsersTab, .mediaCompaniesTab, .mediaMediaTab {
            margin: 5vw;
            font-size: 3.8vw;
        }
        .mediaArticlesUsers, .mediaArticlesCompanies, .mediaArticlesMedia {
            flex-direction: column;
            margin-bottom: 10vw;
        }
        .mediaArticlesUsersTeaser {
            width: 80vw;
            font-size: 3.8vw;
        }
        .mediaArticles2 {
            margin-top: 0;
        }
        .mediaArticles {
            flex-direction: column;
            margin-top: 0;
        }
        .mediaArticle {
            width: 80vw;
            border-radius: 1.3vw;
            margin: 1.3vw;
            padding: 4vw;
        }
        .mediaVideo {
            width: 80vw;
            border-radius: 1.3vw;
            margin: 1.3vw;
            padding: 4vw;
        }
        .mediaTitle {
            font-size: 4.5vw;
            margin-top: 3vw;
        }
        .mediaText {
            font-size: 4vw;
            margin-top: 3vw;
        }
        .mediaLogoNameContainer {
            margin-top: 10vw;
        }
        .mediaLogo {
            width: 6vw;
            height: 6vw;
        }
        .mediaName {
            font-size: 4vw;
            margin-left: 4vw;
        }
        .headerMedia {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 7.5vw;
            margin: 0;
            margin-bottom: 6vw;
            width: 90vw;
        }
        .mediaArticlesContainer {
            flex-direction: column;
        }
        .mediaArticles2 {
            margin-top: 0;
        }
        .mediaArticle {
            width: 80vw;
            border-radius: 1.3vw;
            margin: 1.3vw;
            padding: 7vw;
        }
        .mediaTitle {
            font-size: 4.5vw;
            margin-top: 3vw;
        }
        .mediaText {
            font-size: 4vw;
            margin-top: 3vw;
        }
        .mediaLogoNameContainer {
            margin-top: 10vw;
        }
        .mediaLogo {
            width: 6vw;
            height: 6vw;
        }
        .mediaName {
            font-size: 4vw;
            margin-left: 4vw;
        }
        .youtube-video-frame {
            width: 100vw; 
            height: 80vw;
        }

        .youtube-video-frame-media {
            // width: 80vw;
            height: 50vw;
            margin-top: 0vw;
        }

        .youtube-video-frame-end {
            width: 80vw;
            height: 50vw;
            margin-top: 0vw;
        }

        .mediaImages {
            padding-top: 0vw;
            width: 90vw;
        }
        .mediaLogoImage {
            padding: 6vw;
            height: 17vw;
            width: auto;
            border-radius: 2vw;
        }

        .subheaderPackageDeal {
            font-size: 3.8vw;
            width: 90vw;
            text-align: left;
        }

        .conquerUniverse {
            margin-top: 10vw;
            width: 90vw;
            flex-direction: column;
            flex-wrap: wrap;
            flex-direction: row;  
            width: 100vw;
        }
        .conquerUniverseContainers {
            margin-bottom: 5vw;
            margin-top: 10vw;
        }
        .conquerUniverseContainer {
            width: 45vw;
            height: 60vw;
            justify-content: start;
        }
        .conquerUniverseimageContainer {
            height: 28vw;
            margin-bottom: 2vw;
        }
        .conquerUniverseimage {
            height: 70vw;
            width: auto;
        }

        .usersFeedbackContainer {
            flex-direction: column;
            margin-top: 7vw;
            height: 130vw;
            width: 90vw;
        }
        .userFeedbackContainer {
            height: 60vw;
            width: 90vw;
        }
        .subheaderPodium {
            width: 80vw;
            font-size: 3.8vw;
            padding-bottom: 1.5vw;
            text-align: center;
        }
        .podiumImageContainer {
            height: 130vw;
            width: 90vw;
            margin-top: 10vw;
        }
        .podiumImage {
            height: 150vw;
            margin-right: 0em;
        }

        .contactButton {
            margin-top: 20vw;
            font-size: 3.3vw;
            border-radius: 20vw;
            margin-bottom: 25vw;
        }


        .memolifeFamily {
            display: none;
        }
        .memolifeFamilyMobile {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20vw;
            width: 95vw;
            color: rgba(255, 255, 255, 0.219);
            margin-top: 25vw;
        }
        .memolifeFamilyMobileImage {
            width: 90vw;
            height: auto;
        }
        .contactButton {
            margin-top: 5vw;
            margin-bottom: 1vw;
            font-size: 3.3vw;
            border-radius: 20vw;
            width: auto;
            padding: 3.5vw 6vw 3.5vw 6vw;
        }


        .footer {
            width: 100vw;
            padding-bottom: 15vw;
        }
        .footerValueProp {
            font-size: 3.8vw;
            text-align: center;
            margin-bottom: 0;
            width: 95vw;
        }
        .footerCompanyName {
            font-size: 3.8vw;
            opacity: 0.6;
            margin-bottom: 0;
        }
        .footerLinks {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            padding: 4vw;
            padding-top: 6vw;
            padding-bottom: 6vw;
            width: 100vw;
        }
        .footerLink {
            padding: 3vw;
            padding-bottom: 2.2vw;
            padding-top: 2.2vw;
            font-size: 3.5vw;
        }
        .footerIcons {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .footerIcon1 {
            margin-left: 3vw;
            margin-right: 3vw;
            height: 9vw;
            width: 9vw;
            padding: 2.5vw;
        }
        .footerIcon2 {
            margin-left: 3vw;
            margin-right: 3vw;
            height: 9.3vw;
            width: 9.3vw;
            padding: 2.9vw;
        }

}


@media only screen and (min-width: 600px) and (max-width: 1200px) {
        
    .introDesktop {
        display: flex;
        flex-direction: column;
    }
    .introMobile {
        // display: flex;
    }
    .headerButtonIntroContainer {
        align-items: center;
        height: 50vw;
    }
    .headerIntroContainers {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .headerIntro1, .headerIntro2  {
        font-size: 6.7vw;
        text-align: center;
        width: auto;
    }
    .subheaderIntro {
        font-size: 2.7vw;
        letter-spacing: 0.02em;
        font-weight: 500;
        line-height: 4vw;
        margin-top: 3vw;
        width: 80vw;
        text-align: center;
    }
    .canvasMemomanLiftoff {
        height: 58vw;
    }
    
    .getStartedButton {
        padding: 2vw 4vw 2vw 4vw;
        font-size: 2vw;
        margin-bottom: 7vw;
    }

    .curvedBackground1 {
        clip-path: ellipse(250% 100% at 50% 0%);
        padding-bottom: 0;
    }
    
    .introMediaRow {
        display: flex;
        justify-content: start;
        overflow-y: hidden;
        -ms-overflow-style: none;  
        scrollbar-width: none;
        width: 95vw;
        
    }
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
    .introMediaLogoImage {
        padding: 2vw;
        height: 5.8vw;
        width: auto;
        margin: 1vw;
        margin-top: 1.5vw;
    }
    

    .universe {
        margin-top: 8vw;
        width: 90vw;
    }
    .headerUniverse1, .headerUniverse2  {
        font-size: 5vw;
        width: 80vw;
    }
    .subheaderUniverse {
        font-size: 2.4vw;
        line-height: 3.6vw;
        margin-top: 2.7vw;
        width: 100%;
    }
    .riveContainer {
        height: 45vw;
        padding-top: 2vw;
    }
    .popularTabImage {
        width: 95vw;
        height: auto;
    }
    .canvas {
        height: 67vw;
        // margin-bottom: 7vw;
    }
    .categoryTitlesContainer {
        width: 80vw;
        height: 12vw;
    }
    #categoryPopular, #categoryBrain, #categoryKnowledge, #categoryMind, #categoryBody, #categorySoul, #categoryCareer, #categoryLeisure {
        font-size: 2.2vw;
        line-height: 3vw;
        margin: 2vw;
    }
    .categoryTitle:hover {
        transform: translateY(-4px);
        background:#646efc11;
        color: white;
        opacity: 0.7;
        padding: 0.5em 1em;
        letter-spacing:0.4px;
    }
    
    .galaxyImageContainer {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        /* height: 23em; */
    }
    .galaxyImage {
        padding: 1em;
        /* height: 23em; */
        /* background-color: burlywood; */
    }
    .imageLifeskillNumber {
        position: absolute;
        color: rgba(0, 0, 0, 0.5);
        font-size: 2.3em;
        font-family: "HelveticaNeue", sans-serif;
        opacity: 0;
    }
    .lifeskillsContainer {
        justify-content: center;
        width: 100vw;
    }
    .lifeskillTextContainer {
        width: 70vw;
        margin-bottom: 4vw;
    } 
    .lifeksillText {
        font-size: 2.6vw;
        width: 50vw;
        margin-left: 10vw;
    }
    .lifeskillsTitle {
        width: 95vw;
        margin-bottom: 3vw;
        padding-left: 1.5vw;
        font-size: 2.6vw;
    }
    .lifeskillsGrid {
        justify-content: start;
        width: 95vw;
    }
    .lifeskillPopular, .lifeskillBrain, .lifeskillKnowledge, .lifeskillMind, .lifeskillBody, .lifeskillSoul, .lifeskillCareer, .lifeskillLeisure {
        padding: 1vw 2vw;
        height: 9vw;
        width: 29.6vw;
        font-size: 2vw;
        margin: 1vw;
    }
    .lifeskillSunImage {
        width: 7vw;
        height: 7vw;
    }
    .lifeskillNum {
        padding-top: 0.3vw;
        padding-left: 2.4vw;
        padding-right: 2vw;
        border-radius: 6vw;
        margin-right: 2vw;
        font-size: 1.9vw;
    }
    .lifeskillName {
        font-size: 1.8vw;
    }

    .funBrainhacksContainer {
        margin-top: 15vw;
        width: 100vw;
    }
    .brainHacksHeader {
        font-size: 5vw;
        // width: 80vw;
    }
    .brainHackSubheader {
        font-size: 2.4vw;
        line-height: 3.6vw;
        margin-top: 2.7vw;
        width: 70vw;
    }
    .brainHacksListContainer {
        flex-flow: wrap;
        margin-top: 5vw;
    }
    .brainHackContainer {
        padding: 3vw;
        width: 50vw;
    }
    .brainHackImageContainer {
        height: 20vw;
        width: 40vw;
        margin-bottom: 4vw;
    }
    .brainHackImage {
        width: 44vw;
        height: auto;
    }
    .brainHackTitle {
        font-size: 3vw;
    }
    .brainHackSubtitle {
        font-size: 2vw;
        margin-top: 12px;
        width: 40vw;
    }

    .missions {
        margin-top: 15vw;
        width: 95vw;
    }
    .missionsContainers {
        flex-flow: wrap;
        width: 92vw;
    }
    .missionContainer1, .missionContainer2, .missionContainer3 {
        margin: 2vw;
        margin-bottom: 2vw;
        margin-left: 1vw;
        margin-right: 1vw;
        padding: 3vw;
        padding-top: 2vw;
        width: 43vw;
        border-radius: 1vw;
    }
    .missionContainer3 {
        margin-right: 1vw;
    }
    .missionImage {
        height: 24vw;
        margin-top: 4vw;
        margin-bottom: 3vw;
    }
    .missionTitle {
        font-size: 3vw;
    }
    .missionSubtitle {
        font-size: 2vw;
    }
    

    .mediaContainer {
        margin-top: 15vw;
    }
    .headerMedia {
        font-size: 5vw;
        width: 80vw;
    }
    .mediaUsersTab {
        font-size: 2.4vw;
        line-height: 3.5vw;
        margin: 2vw;
    }
    .mediaMediaTab {
        font-size: 2.4vw;
        line-height: 3.5vw;
        margin: 2vw;
    }
    .mediaCompaniesTab {
        font-size: 2.4vw;
        line-height: 3.5vw;
        margin: 2vw;
    }
    .subheaderMedia {
        font-size: 2.4vw;
        line-height: 3.5vw;
        width: 90vw;
        margin-bottom: 1.1vw;
    }
    .mediaImages {
        padding-top: 4vw;
        width: 85vw;
    }
    .mediaArticle {
        width: 45vw;
        height: 40vw;
        border-radius: 2vw;
        padding: 4vw;
        padding-bottom: 5vw;
        margin: 1vw;
    }
    .mediaTitle {
        font-size: 2.2vw;
        margin-top: 2vw;
    }
    .mediaVideo {
        width: 45vw;
        border-radius: 1vw;
        padding: 2vw;
        margin: 1vw;
    }
    .mediaLogoImage {
        padding: 3.4vw;
        height: 11.5vw;
        width: auto;
        margin: 0.4vw;
        border-radius: 1.5vw;
    }

    
    .conquerUniverse {
        margin-top: 5vw;
        width: 95vw;
    }
    .conquerUniverseContainers {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-flow: row wrap;
        margin-bottom: 1em;
        margin-top: 3em;
    }
    .conquerUniverseContainer {
        width: 45vw;
        height: 34vw;
    }
    .conquerUniverseimageContainer {
        height: 16vw;
        margin-bottom: 2vw;
    }
    .conquerUniverseimage {
        height: 50vw;
        width: auto;
    }

    .memolifeFamily {
        display: none;
    }
    .memolifeFamilyMobile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: rgba(255, 255, 255, 0.219);
        margin-top: 15vw;
        margin-bottom: 25vw;
        width: 95vw;
    }
    .memolifeFamilyMobileImage {
        width: 80vw;
        height: auto;
    }

    .contactButton {
        padding: 2vw 4vw 2vw 4vw;
        font-size: 2vw;
        margin-top: 4vw;
        margin-bottom: 1vw;
    }

}