#section-footer {
  @keyframes blink {
    50% {
      transform: scale(1, 0.6);
    }
  }
  svg {
    margin-top: -10px; // Adjust position of heart, so the top of the heart is aligned to the top of the parent box.

    @each $lifeAreaId, $color in $lifeAreaColors {
      #life-area-#{$lifeAreaId} {fill: $color;}
    }

    rect {
      stroke: $darkBackgroundColor;
      stroke-width: 4px;

      transform-origin: center center;
      // Disabled because it doesn't work in Firefox
      //animation: blink 8s linear infinite alternate;
    }
  }

  .body {
    $verticalHeartSpace: calc(20px + 4%);

    padding-top: 0px;

    .heart {
      width: 120px;
      margin: auto;
      margin-top: $verticalHeartSpace;
      //margin-bottom: $sectionVerticalGap;
    }

    .learnMore {
      margin-top: 20px;
      font-weight: 300;
      text-align: center;
      //margin-bottom: $sectionVerticalGap;
      color: mix($darkBackgroundColor, $darkbgTextColorA, 50%);
      font-style: italic;

      a {
        color: inherit;
      }
    }

    ul {
      margin-top: $verticalHeartSpace;
      margin-bottom: -1.5em; // Compensate for the bottom li's padding
    }

    li {
      padding-bottom: 1.5em;

      .a {
        color: $darkbgTextColorA;
      }
      .b {
        margin-top: 0.5em;
        font-weight: lighter;
        font-size: 90%;
      }
    }
  }

  .call-to-action {
    background-color: $lightBackgroundColor;
    text-align: center;
    padding: 30px;

    .button {
      @include standard-green-button();
    }
  }

  .footer {
    height: 80px;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 80%;
    color: gray;


    padding: 0px 60px; // Prevents content from being overlapped by the floating 'support'-button


    .copyright {
    }

    .terms {
      margin-top: 0.5em;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .separator {
      margin: 0px 0.2em;
    }
  }

  // Desktop
  @media (min-width: $mobileBreakpoint + 1px) {
    .body {
      ul {
        //max-width: 800px;
        column-count: 2;
        column-gap: 40px;
      }

      li {
        break-inside: avoid;
      }
    }
    .footer {
      padding: 0px 140px; // Prevents content from being overlapped by the floating 'support'-button
    }
  }

  // Large
  @media (min-width: $largescreenBreakpoint + 1px) {
    .body {
      ul {
        column-count: 3;
        column-gap: 30px;
      }
    }
  }
}