.video-player {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(47, 52, 65, 0.92); // bluish
  z-index: 2;

  &:not(.active) {
    display: none;
  }


  .video-frame {
    position: relative;
    width: 80%;
    max-width: 600px;
    background-color: white;
    border: 10px solid white;
    border-radius: 2px;

    &::after {
      content: "";
      display: block;
      //padding-bottom: percentage(9/16);
      padding-bottom: percentage(3/4);
    }

    iframe {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100% !important;
      width: 100% !important;
    }

    .close-button {
      position: absolute;
      top: -42px;
      right: -16px;
      width: 30px;
      height: 30px;

      background-image: hashed-url("images/_shared/icon-x-white.svg");
      background-size: 70%;
      background-position: center;
      background-repeat: no-repeat;

      cursor: pointer;
    }
  }
}