@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro");
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  position: relative;
  margin: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  background-color: #D0D0D0;
  max-width: 1280px;
  margin: auto; }

ul {
  list-style: none;
  padding: 0px;
  margin: 0px; }

section.has-standard-header > .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: calc(15px + 5%) 10% 0px 10%; }

section.has-standard-body > .body {
  padding: calc(15px + 5%) 10% 40px 10%; }

section.has-standard-header.has-standard-body > .body {
  padding-top: 40px; }

section > .header .title {
  font-size: 140%;
  font-weight: bold;
  max-width: 600px;
  width: 100%; }

section > .header .subtitle {
  margin-top: 1em;
  max-width: 600px;
  width: 100%; }

section.darkbg {
  background-color: #191A37;
  color: #DADAEA; }
  section.darkbg > .header .title {
    color: white;
    font-weight: normal; }
  section.darkbg > .header .subtitle {
    font-weight: 300; }

section.whitebg {
  background-color: white;
  color: #4b4c56; }
  section.whitebg > .header .title {
    color: #292b32; }

section.lightbg {
  background-color: #f6f6f6;
  color: #4b4c56; }
  section.lightbg > .header .title {
    color: #292b32; }

@media (min-width: 501px) {
  #section-banner > .header .title, #section-banner .xbannerHeader .title, .xsectionBanner > .header .title, .xsectionBanner .xbannerHeader .title {
    font-size: 5vw; }
  #section-banner > .header .subtitle, #section-banner .xbannerHeader .subtitle, .xsectionBanner > .header .subtitle, .xsectionBanner .xbannerHeader .subtitle {
    font-size: 3vw; } }

@media (min-width: 640px) {
  #section-banner > .header .title, #section-banner .bannerHeader .title, .xsectionBanner > .header .title, .xsectionBanner .bannerHeader .title {
    font-size: 32px; }
  #section-banner > .header .subtitle, #section-banner .bannerHeader .subtitle, .xsectionBanner > .header .subtitle, .xsectionBanner .bannerHeader .subtitle {
    font-size: 19.2px; } }

.product-icon {
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2px; }
  .product-icon[style*="/logo"] {
    background-color: #191A37;
    background-size: 70%; }
  .product-icon[style*="/videos-and-articles"] {
    background-size: cover; }
  .product-icon[style*="/memomaps"] {
    background-size: 60%; }

#section-header {
  background-color: white; }
  #section-header .productbox-container {
    position: absolute;
    top: 80px;
    z-index: 1;
    width: 100%;
    clear: both;
    display: none; }
    #section-header .productbox-container.expanded {
      display: block; }
  #section-header .productbox {
    width: 600px;
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    background-color: white;
    border: 1px solid #f6f6f6;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); }
  #section-header .productbox-left {
    display: flex;
    flex-direction: column;
    padding: 2em;
    width: 35%;
    background-color: #f6f6f6; }
    #section-header .productbox-left .title {
      font-weight: 600;
      font-size: 120%;
      color: #292b32; }
    #section-header .productbox-left .subtitle {
      color: #4b4c56;
      margin-top: 1em;
      font-size: 90%;
      line-height: 1.5em; }
    #section-header .productbox-left .button {
      display: inline-block;
      background-color: #63CB6C;
      border-radius: 2px;
      color: white;
      padding: 10px 20px;
      cursor: pointer;
      text-align: center;
      margin-top: 1.5em; }
      #section-header .productbox-left .button a {
        padding: 10px 20px; }
      #section-header .productbox-left .button:hover {
        background-color: #50c55a; }
      #section-header .productbox-left .button:active {
        background-color: #3fbc4a; }
  #section-header .productbox-right {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 2em; }
    #section-header .productbox-right ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: -1em 0px; }
    #section-header .productbox-right li {
      width: 33.33333%;
      text-align: center;
      margin: 1em 0px; }
    #section-header .productbox-right .icon {
      display: inline-block;
      width: 4em;
      height: 4em;
      background-color: #f6f6f6;
      border: 1px solid #f1f1f1;
      margin-bottom: 0.5em; }
    #section-header .productbox-right .title {
      font-size: 80%;
      color: #4b4c56; }
  #section-header .pushdownMenu {
    clear: both;
    max-height: 0;
    transition: max-height .5s ease-out;
    background-color: white;
    overflow: hidden;
    top: 80px;
    width: 100%;
    color: #d5d5d9; }
    #section-header .pushdownMenu.expanded {
      max-height: 380px; }
    #section-header .pushdownMenu .menu-content {
      display: flex;
      background-color: #2c2d42;
      flex-direction: column;
      align-items: center;
      padding: 10px calc(10% - 10px); }
      #section-header .pushdownMenu .menu-content .item-with-atag {
        padding: 0.6em; }
        #section-header .pushdownMenu .menu-content .item-with-atag:hover {
          color: white; }
    #section-header .pushdownMenu li.divider {
      height: 1px;
      margin: 0.4em 0;
      background-color: #37384b;
      width: 100%;
      max-width: 400px; }
    #section-header .pushdownMenu li[data-lang] a {
      padding: 10px;
      padding-left: 45px; }
    #section-header .pushdownMenu li[data-lang]:hover {
      color: white; }
    #section-header .pushdownMenu li[data-lang].active {
      color: white; }
      #section-header .pushdownMenu li[data-lang].active a::after {
        content: "";
        display: inline-block;
        background-image: url("../images/_shared/checkmark-4e2f9e8dc4.svg");
        background-size: 100%;
        background-position: center;
        height: 12px;
        width: 12px;
        margin-left: 6px; }
  #section-header .mainSection {
    display: flex;
    align-items: center;
    height: 80px; }
    #section-header .mainSection .school, #section-header .mainSection .business, #section-header .mainSection .products, #section-header .mainSection .pricing, #section-header .mainSection .login, #section-header .mainSection .about, #section-header .mainSection .workHappiness {
      color: gray; }
      #section-header .mainSection .school:hover, #section-header .mainSection .business:hover, #section-header .mainSection .products:hover, #section-header .mainSection .pricing:hover, #section-header .mainSection .login:hover, #section-header .mainSection .about:hover, #section-header .mainSection .workHappiness:hover {
        color: black; }
    #section-header .mainSection .school, #section-header .mainSection .business, #section-header .mainSection .about, #section-header .mainSection .products, #section-header .mainSection .pricing a, #section-header .mainSection .login a, #section-header .mainSection .workHappiness {
      padding: 1em 0px;
      margin: 0px 0.4em; }
    #section-header .mainSection .signup {
      margin-right: 40px;
      margin-left: 0.8em;
      background-color: #63CB6C;
      border-radius: 2px;
      color: white; }
      #section-header .mainSection .signup a {
        padding: 0.6em 0.8em;
        display: block; }
    #section-header .mainSection .products {
      position: relative;
      cursor: pointer; }
      #section-header .mainSection .products.active {
        color: black; }
        #section-header .mainSection .products.active:before {
          content: "";
          bottom: 0px;
          width: 100%;
          display: block;
          height: 1px;
          background-color: black;
          position: absolute; }
    #section-header .mainSection .languagemenu-icon {
      display: block;
      padding: 10px;
      padding-left: 35px;
      cursor: pointer; }
      #section-header .mainSection .languagemenu-icon::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 5px solid black;
        margin-left: 7px;
        position: relative;
        top: -2px; }
    #section-header .mainSection .languagemenu-btn {
      display: none; }
      #section-header .mainSection .languagemenu-btn:checked ~ .languagemenu {
        display: block; }
    #section-header .mainSection .languagemenu {
      position: absolute;
      background-color: white;
      display: none;
      margin-top: 10px;
      z-index: 2; }
      #section-header .mainSection .languagemenu [data-lang] a {
        padding: 10px 0px;
        padding-left: 45px; }
  #section-header .flex-spacer {
    flex: 1; }
  #section-header .logo {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    padding-right: 0px;
    margin-right: 0.4em; }
    #section-header .logo::before {
      display: inline-block;
      content: "";
      width: 2.5em;
      height: 2.5em;
      background-image: url("../images/_shared/logo-221e84999e.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 0.5em; }
    #section-header .logo::after {
      display: inline-block;
      content: "";
      width: 7.8em;
      height: 1.2em;
      margin-bottom: 0.2em;
      background-image: url("../images/_shared/memolife-75763dbdb6.svg");
      background-size: auto 100%;
      background-repeat: no-repeat; }
  #section-header #pushdownmenu-btn {
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 27px;
    padding-left: 17px;
    padding-top: 2px;
    cursor: pointer;
    position: relative;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; }
    #section-header #pushdownmenu-btn::after {
      content: "";
      display: block;
      width: 100px;
      position: absolute;
      height: 30px;
      right: 16px;
      bottom: 0px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 14px solid #2c2d42;
      border-bottom-width: 0px;
      transition: border-bottom-width 0.5s; }
    #section-header #pushdownmenu-btn .navicon {
      background: #333;
      display: block;
      height: 2px;
      position: relative;
      transition: background .5s ease-out;
      width: 18px; }
    #section-header #pushdownmenu-btn .navicon:before,
    #section-header #pushdownmenu-btn .navicon:after {
      background: #333;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .5s ease-out;
      width: 100%; }
    #section-header #pushdownmenu-btn .navicon:before {
      top: 5px; }
    #section-header #pushdownmenu-btn .navicon:after {
      top: -5px; }
    #section-header #pushdownmenu-btn.active .menu {
      max-height: 340px; }
    #section-header #pushdownmenu-btn.active::after {
      border-bottom-width: 14px; }
    #section-header #pushdownmenu-btn.active .navicon {
      background: transparent; }
    #section-header #pushdownmenu-btn.active .navicon::before {
      top: 0px;
      transform: rotate(-45deg); }
    #section-header #pushdownmenu-btn.active .navicon::after {
      top: 0px;
      transform: rotate(45deg); }
  #section-header li a {
    display: block; }
  #section-header a {
    text-decoration: none;
    color: inherit; }
  #section-header [data-lang] {
    background-size: 20px;
    background-position: 15px center;
    background-repeat: no-repeat; }
    #section-header [data-lang] a {
      padding-left: 45px; }
  #section-header [data-lang="EN"] {
    background-image: url("../images/_shared/lang-icons/us-6c4096b9f9.svg"); }
  #section-header [data-lang="NO"] {
    background-image: url("../images/_shared/lang-icons/no-7bc16f1bda.svg"); }
  #section-header li a {
    display: block;
    text-decoration: none;
    color: inherit; }
  #section-header li.active a::after {
    content: "";
    display: inline-block;
    background-image: url("../images/_shared/checkmark-black-b7ea7a690c.svg");
    background-size: 100%;
    background-position: center;
    height: 12px;
    width: 12px;
    margin-left: 6px; }
  #section-header .blockingLayer {
    position: fixed;
    top: 80px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    display: none; }
  #section-header.darkbg {
    background-color: #233c7c; }
    #section-header.darkbg .logo::after {
      background-image: url("../images/_shared/memolife-white-e966b280ae.svg"); }
    #section-header.darkbg .mainSection .school, #section-header.darkbg .mainSection .business, #section-header.darkbg .mainSection .products, #section-header.darkbg .mainSection .pricing, #section-header.darkbg .mainSection .login, #section-header.darkbg .mainSection .about, #section-header.darkbg .mainSection .workHappiness {
      color: rgba(255, 255, 255, 0.8); }
      #section-header.darkbg .mainSection .school:hover, #section-header.darkbg .mainSection .business:hover, #section-header.darkbg .mainSection .products:hover, #section-header.darkbg .mainSection .pricing:hover, #section-header.darkbg .mainSection .login:hover, #section-header.darkbg .mainSection .about:hover, #section-header.darkbg .mainSection .workHappiness:hover {
        color: white; }
    #section-header.darkbg .mainSection .languagemenu {
      background-color: transparent; }
      #section-header.darkbg .mainSection .languagemenu li:hover {
        color: white; }
      #section-header.darkbg .mainSection .languagemenu li.active {
        color: white; }
        #section-header.darkbg .mainSection .languagemenu li.active a::after {
          background-image: url("../images/_shared/checkmark-4e2f9e8dc4.svg"); }
    #section-header.darkbg .mainSection .languagemenu-icon::after {
      border-top: 5px solid rgba(255, 255, 255, 0.8); }
    #section-header.darkbg .mainSection .languagemenu-icon:hover::after {
      border-top: 5px solid white; }
    #section-header.darkbg #pushdownmenu-btn::after {
      border-bottom-color: white; }
    #section-header.darkbg #pushdownmenu-btn:not(.active) .navicon {
      background: white; }
    #section-header.darkbg #pushdownmenu-btn .navicon:before,
    #section-header.darkbg #pushdownmenu-btn .navicon:after {
      background: white; }
    #section-header.darkbg .pushdownMenu {
      color: rgba(0, 0, 0, 0.8); }
      #section-header.darkbg .pushdownMenu li.divider {
        background-color: rgba(0, 0, 0, 0.05); }
      #section-header.darkbg .pushdownMenu li[data-lang].active {
        color: black; }
        #section-header.darkbg .pushdownMenu li[data-lang].active a::after {
          background-image: url("../images/_shared/checkmark-black-b7ea7a690c.svg"); }
      #section-header.darkbg .pushdownMenu li[data-lang]:hover {
        color: black; }
    #section-header.darkbg .menu-content {
      background-color: white; }
      #section-header.darkbg .menu-content .item-with-atag:hover {
        color: black; }
  @media (max-width: 600px) {
    #section-header {
      position: relative;
      z-index: 1; }
      #section-header .mainSection .school, #section-header .mainSection .business, #section-header .mainSection .languages, #section-header .mainSection .about, #section-header .mainSection .products, #section-header .mainSection .pricing, #section-header .mainSection .login, #section-header .mainSection .signup, #section-header .mainSection .workHappiness {
        display: none; }
      #section-header .productbox-container.expanded {
        display: none; } }
  @media (min-width: 601px) and (max-width: 960px) {
    #section-header .mainSection .school, #section-header .mainSection .business, #section-header .mainSection .languages, #section-header .mainSection .about, #section-header .mainSection .signup {
      display: none; }
    #section-header .pushdownMenu .pricing, #section-header .pushdownMenu .login {
      display: none; } }
  @media (min-width: 961px) {
    #section-header .mainSection #pushdownmenu-btn {
      display: none; }
    #section-header .productbox {
      margin-right: 40px; }
    #section-header .pushdownMenu {
      display: none; } }

@keyframes blink {
  50% {
    transform: scale(1, 0.6); } }

#section-footer svg {
  margin-top: -10px; }
  #section-footer svg #life-area-brain {
    fill: #18af58; }
  #section-footer svg #life-area-knowledge {
    fill: #9885c2; }
  #section-footer svg #life-area-mind {
    fill: #d94d36; }
  #section-footer svg #life-area-body {
    fill: #e48831; }
  #section-footer svg #life-area-soul {
    fill: #e8b730; }
  #section-footer svg #life-area-career {
    fill: #2d7392; }
  #section-footer svg #life-area-leisure {
    fill: #732985; }
  #section-footer svg rect {
    stroke: #191A37;
    stroke-width: 4px;
    transform-origin: center center; }

#section-footer .body {
  padding-top: 0px; }
  #section-footer .body .heart {
    width: 120px;
    margin: auto;
    margin-top: calc(20px + 4%); }
  #section-footer .body .learnMore {
    margin-top: 20px;
    font-weight: 300;
    text-align: center;
    color: #8c8d9b;
    font-style: italic; }
    #section-footer .body .learnMore a {
      color: inherit; }
  #section-footer .body ul {
    margin-top: calc(20px + 4%);
    margin-bottom: -1.5em; }
  #section-footer .body li {
    padding-bottom: 1.5em; }
    #section-footer .body li .a {
      color: white; }
    #section-footer .body li .b {
      margin-top: 0.5em;
      font-weight: lighter;
      font-size: 90%; }

#section-footer .call-to-action {
  background-color: #f6f6f6;
  text-align: center;
  padding: 30px; }
  #section-footer .call-to-action .button {
    display: inline-block;
    background-color: #63CB6C;
    border-radius: 2px;
    color: white;
    padding: 10px 20px;
    cursor: pointer; }
    #section-footer .call-to-action .button a {
      padding: 10px 20px; }
    #section-footer .call-to-action .button:hover {
      background-color: #50c55a; }
    #section-footer .call-to-action .button:active {
      background-color: #3fbc4a; }

#section-footer .footer {
  height: 80px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 80%;
  color: gray;
  padding: 0px 60px; }
  #section-footer .footer .terms {
    margin-top: 0.5em; }
  #section-footer .footer a {
    color: inherit;
    text-decoration: none; }
  #section-footer .footer .separator {
    margin: 0px 0.2em; }

@media (min-width: 501px) {
  #section-footer .body ul {
    column-count: 2;
    column-gap: 40px; }
  #section-footer .body li {
    break-inside: avoid; }
  #section-footer .footer {
    padding: 0px 140px; } }

@media (min-width: 769px) {
  #section-footer .body ul {
    column-count: 3;
    column-gap: 30px; } }

.zendeskbutton {
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  background-color: #63CB6C;
  color: white;
  z-index: 2;
  font-weight: 600;
  bottom: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); }
  .zendeskbutton::before {
    position: absolute;
    left: 0px;
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url("../images/_shared/icon-envelope-cc131240d0.svg");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center; }
  @media (max-width: 500px) {
    .zendeskbutton {
      height: 40px;
      width: 40px;
      right: 10px;
      bottom: 10px;
      border-radius: 50%; }
      .zendeskbutton .label {
        display: none; } }
  @media (min-width: 501px) {
    .zendeskbutton {
      border-radius: 23px 0px 0px 23px;
      padding-left: 53px;
      padding-right: 10px;
      right: 0px;
      height: 46px;
      line-height: 46px;
      background-color: #3fbc4a; }
      .zendeskbutton::before {
        background-color: #63CB6C;
        top: 3px;
        left: 3px; } }

[data-carousel] {
  position: relative; }
  [data-carousel] ul {
    display: flex;
    width: 33.33333%;
    transition: transform 1s;
    transform: translate3d(0, 0, 0); }
  [data-carousel] li {
    transition: transform 1s;
    transform: translate3d(0, 0, 0);
    width: 100%;
    min-width: 100%; }
  [data-carousel][data-carousel-size="1"] ul {
    width: 100%; }
  [data-carousel][data-carousel-size="2"] ul {
    width: 50%; }
  [data-carousel][data-carousel-size="3"] ul {
    width: 33.33333%; }
  [data-carousel][data-carousel-size="4"] ul {
    width: 25%; }
  [data-carousel][data-carousel-size="5"] ul {
    width: 20%; }
  [data-carousel][data-carousel-size="6"] ul {
    width: 16.66667%; }
  [data-carousel][data-carousel-size="2"] .slides-container {
    width: 24em; }
  [data-carousel][data-carousel-prev="0"] .prev,
  [data-carousel][data-carousel-next="0"] .next {
    background-color: #7fb7db;
    cursor: default; }
  [data-carousel][data-carousel-prev="0"][data-carousel-next="0"] .prev, [data-carousel][data-carousel-prev="0"][data-carousel-next="0"] .next {
    display: none; }
  [data-carousel] .prev,
  [data-carousel] .next {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    margin-top: -15px;
    background-color: #1c80c6;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 2; }
    [data-carousel] .prev::after,
    [data-carousel] .next::after {
      content: "";
      display: block;
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px; }
  [data-carousel] .prev {
    left: 0px;
    border-radius: 0 2px 2px 0;
    background-image: url("../images/_shared/icon-chevron-left-e61ba2c4c9.svg");
    background-position: left center; }
    [data-carousel] .prev::after {
      left: 0px; }
  [data-carousel] .next {
    right: 0px;
    border-radius: 2px 0 0 2px;
    background-image: url("../images/_shared/icon-chevron-right-71ea218ff4.svg"); }
    [data-carousel] .next::after {
      right: 0px; }
  [data-carousel] .slides-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: auto;
    overflow: hidden;
    padding: 0px 40px; }
    [data-carousel] .slides-container::before, [data-carousel] .slides-container::after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 40px;
      z-index: 1; }
    [data-carousel] .slides-container::before {
      left: 0px;
      background: linear-gradient(to right, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%); }
    [data-carousel] .slides-container::after {
      right: 0px;
      background: linear-gradient(to left, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%); }
  [data-carousel][data-carousel-prev="1"] ul {
    transform: translateX(-100%) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-prev="1"] li:nth-child(1) {
    transform: translateX(-40px) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-next="1"] li:nth-last-child(1) {
    transform: translateX(40px) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-prev="2"] ul {
    transform: translateX(-200%) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-prev="2"] li:nth-child(2) {
    transform: translateX(-40px) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-next="2"] li:nth-last-child(2) {
    transform: translateX(40px) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-prev="3"] ul {
    transform: translateX(-300%) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-prev="3"] li:nth-child(3) {
    transform: translateX(-40px) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-next="3"] li:nth-last-child(3) {
    transform: translateX(40px) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-prev="4"] ul {
    transform: translateX(-400%) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-prev="4"] li:nth-child(4) {
    transform: translateX(-40px) translate3d(0, 0, 0); }
  [data-carousel][data-carousel-next="4"] li:nth-last-child(4) {
    transform: translateX(40px) translate3d(0, 0, 0); }

.video-player {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(47, 52, 65, 0.92);
  z-index: 2; }
  .video-player:not(.active) {
    display: none; }
  .video-player .video-frame {
    position: relative;
    width: 80%;
    max-width: 600px;
    background-color: white;
    border: 10px solid white;
    border-radius: 2px; }
    .video-player .video-frame::after {
      content: "";
      display: block;
      padding-bottom: 75%; }
    .video-player .video-frame iframe {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100% !important;
      width: 100% !important; }
    .video-player .video-frame .close-button {
      position: absolute;
      top: -42px;
      right: -16px;
      width: 30px;
      height: 30px;
      background-image: url("../images/_shared/icon-x-white-8c90059fc0.svg");
      background-size: 70%;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer; }

@media (max-width: 500px) {
  body {
    font-size: 14px; } }

.h1 {
  color: #D6D4EF;
  font-size: 3.8em;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.02em;
  font-weight: 600; }

.h2 {
  color: #D6D4EF;
  font-size: 2.5em;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.02em;
  font-weight: 600; }

.h3 {
  font-size: 2em;
  color: #D6D4EF;
  letter-spacing: 0.02em;
  font-family: Source Sans Pro;
  font-weight: 500; }

.h4 {
  font-size: 1.5em;
  color: #D6D4EF;
  letter-spacing: 0.02em;
  font-family: Source Sans Pro;
  font-weight: 500; }

.subtitle {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: 1.6em; }

.paragraph {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.1em;
  letter-spacing: 0.02em; }

.uppertitle {
  color: #9eaeee;
  letter-spacing: 0.1em;
  font-family: Source Sans Pro;
  font-size: 0.8em;
  font-weight: 600; }

@media only screen and (max-width: 600px) {
  .h1 {
    font-size: 8vw; }
  .h2 {
    font-size: 7vw; }
  .h3 {
    font-size: 6vw; }
  .h4 {
    font-size: 4.7vw; }
  .subtitle {
    font-size: 4.3vw; }
  .paragraph {
    font-size: 3.4vw; }
  .uppertitle {
    font-size: 2.7vw; } }

#section-header {
  background-color: transparent;
  z-index: 10000; }
  #section-header .mainSection {
    width: 100vw;
    background-color: #090936;
    position: fixed;
    top: 0;
    left: 0; }
    #section-header .mainSection .languagemenu {
      background-color: transparent; }
    #section-header .mainSection .languagemenu-icon {
      display: none; }
    #section-header .mainSection .languagemenu-icon::after {
      border-top: 5px solid #c6c3d3; }
    #section-header .mainSection .business {
      color: #c6c3d3; }
    #section-header .mainSection .school {
      display: none; }
    #section-header .mainSection .about {
      color: #c6c3d3; }
    #section-header .mainSection .products {
      display: none; }
    #section-header .mainSection .products.active {
      color: #c6c3d3; }
    #section-header .mainSection a {
      color: #c6c3d3; }
    #section-header .mainSection .signup {
      background-color: #5a63e4; }
      #section-header .mainSection .signup:hover {
        background-color: #3e46bd; }
  #section-header #pushdownmenu-btn .navicon:after {
    background: #c6c3d3; }
  #section-header #pushdownmenu-btn .navicon {
    background: #c6c3d3; }
  #section-header #pushdownmenu-btn .navicon:before {
    background: #c6c3d3; }
  #section-header .pushdownMenu .menu-content {
    background-color: #181752; }
  #section-header #pushdownmenu-btn::after {
    border-bottom: 14px solid #181752;
    border-bottom-width: 0px; }
  #section-header #section-header #pushdownmenu-btn.active::after {
    border-bottom-width: 14px; }
  #section-header .logo::after {
    background-image: url("../images/_shared/memolife-white-e966b280ae.svg"); }

html {
  overflow-x: hidden; }

::-webkit-scrollbar {
  background: transparent; }

::-webkit-scrollbar-thumb {
  background-color: #0e0c38; }

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0e0c3d;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: hidden; }

.curvedBackground1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #090936;
  -webkit-clip-path: ellipse(150% 100% at 50% 0%);
  clip-path: ellipse(150% 100% at 50% 0%);
  width: 100vw;
  padding-bottom: 6em;
  padding-top: 9em; }

.introDesktop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70em; }

.introMobile {
  display: none; }

.introAnimContainer {
  height: 25em;
  display: flex;
  align-items: center; }

.canvasMemomanLiftoff {
  height: 400px; }

.headerButtonIntroContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100vw;
  height: 20.4em;
  z-index: 1000; }

.headerIntro1, .headerIntro2 {
  color: #D6D4EF;
  font-size: 3.2em;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin: 0;
  width: 12.8em;
  text-align: start; }

.subheaderIntro {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: 1.6em;
  padding: 0em;
  width: 28.5em;
  margin-bottom: 0;
  text-align: start; }

.getStartedButton {
  color: #D6D4EF;
  letter-spacing: 0.02em;
  font-family: Source Sans Pro;
  background-color: #4452FE;
  width: auto;
  margin-top: 3em;
  padding: 1em 2em 1em 2em;
  font-size: 1em;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all .2s ease-in;
  z-index: 10000; }

.getStartedButton:hover {
  position: relative;
  transform: translateY(-4px);
  background: #2D3CF5;
  letter-spacing: 0.4px; }

.imageIntro {
  position: absolute;
  width: 70em;
  padding-top: 5em;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(black), to(rgba(0, 0, 0, 0)));
  mask-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0)); }

.introMediaRow {
  display: flex;
  justify-content: center;
  opacity: 0.6; }

.introMediaLogoImage {
  padding: 1em;
  height: 3.14em;
  width: auto;
  margin: 0.3em;
  margin-top: 0.5em;
  border-radius: 0.5em; }

.missions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* min-height: 80em; */
  margin-top: 8em;
  width: 70em;
  z-index: 1000; }

.missionsContainers {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
  /* padding: 2em; */ }

.missionContainer1, .missionContainer2, .missionContainer3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1b174e62;
  margin: 1em;
  margin-bottom: 1em;
  margin-left: 0em;
  padding: 1.5em;
  padding-top: 0.5em;
  width: 100%;
  border-radius: 0.6em;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */ }

.missionContainer3 {
  margin-right: 0em; }

.missionImage {
  width: 11em;
  height: 12em;
  margin-top: 2em;
  margin-bottom: 2em; }

.missionTitle {
  font-size: 1.3em;
  color: #D6D4EF;
  letter-spacing: 0.02em;
  font-family: Source Sans Pro;
  margin-bottom: 12px;
  margin-top: 0; }

.missionSubtitle {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  width: 15em;
  text-align: center;
  margin-top: 0; }

.funBrainhacksContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8em;
  width: 70em; }

.brainHacksHeader {
  color: #D6D4EF;
  font-size: 2.5em;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.02em;
  line-height: 1.1em;
  font-weight: 600;
  margin: 0; }

.brainHackSubheader {
  display: flex;
  justify-content: center;
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  letter-spacing: 0.02em;
  font-weight: 500;
  width: 30em;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px; }

.brainHacksListContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: start;
  margin-top: 2em; }

.brainHackContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2em; }

.brainHackImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10em;
  width: 13em;
  margin-bottom: 2em;
  /* background-color: #1b174e62; */ }

.brainHackImage {
  width: 23em;
  height: auto; }

.brainHackTitle {
  font-size: 1.5em;
  color: #D6D4EF;
  letter-spacing: 0.02em;
  font-family: Source Sans Pro;
  margin: 0; }

.brainHackSubtitle {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: .9em;
  letter-spacing: 0.02em;
  font-weight: 500;
  margin-top: 0.6em; }

.universe {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* min-height: 80em; */
  margin-top: 6em;
  width: 70em;
  z-index: 1000; }

.headerUniverse1, .headerUniverse2 {
  display: flex;
  justify-content: center;
  color: #D6D4EF;
  font-size: 2.5em;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin: 0;
  width: 20em; }

.subheaderUniverse {
  display: none;
  justify-content: center;
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  letter-spacing: 0.02em;
  font-weight: 500;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0.4em; }

.lifeskillTextContainer {
  position: absolute;
  display: flex;
  align-items: center;
  width: 20em;
  margin-bottom: 2em;
  opacity: 0;
  transition: opacity 1s ease-in; }

.lifeksillText {
  text-align: left;
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.1em;
  letter-spacing: 0.02em; }

.riveContainer {
  height: 22em;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center; }

.canvas {
  height: 430px; }

.popularTabImage {
  display: flex;
  width: 55em;
  height: auto;
  margin-bottom: 0; }

.popularTabImageMobile {
  display: none; }

.categoryTitlesContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-family: "HelveticaNeue", sans-serif; }

#categoryPopular, #categoryBrain, #categoryKnowledge, #categoryMind, #categoryBody, #categorySoul, #categoryCareer, #categoryLeisure {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: 1.3em;
  margin: 1em;
  opacity: .6;
  cursor: pointer;
  transition: all .2s ease-in;
  border-radius: 6em;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  z-index: 1000; }

.categoryTitle:hover {
  background: #646efc11;
  color: white;
  opacity: 0.7;
  padding: 0.5em 1em; }

.galaxyImageContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  /* height: 23em; */ }

.galaxyImage {
  padding: 1em;
  /* height: 23em; */
  /* background-color: burlywood; */ }

.imageLifeskillNumber {
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  font-size: 2.3em;
  font-family: "HelveticaNeue", sans-serif;
  opacity: 0; }

.lifeskillsContainer {
  display: flex;
  justify-content: start;
  flex-flow: row wrap;
  align-items: center;
  /* height: 16em; */
  width: 70em; }

.lifeskillsTitle {
  position: absolute;
  align-self: end;
  font-family: 'Source Sans Pro';
  text-align: center;
  font-size: 1.3em;
  color: #9a9dff;
  opacity: 1;
  margin-bottom: 1.5em; }

.lifeskillsGrid {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  width: 70em; }

.lifeskillPopular, .lifeskillBrain, .lifeskillKnowledge, .lifeskillMind, .lifeskillBody, .lifeskillSoul, .lifeskillCareer, .lifeskillLeisure {
  display: flex;
  justify-content: start;
  cursor: pointer;
  align-items: center;
  padding: 0.3em 1em;
  background-color: #1b174eb3;
  height: 4em;
  width: 14.37em;
  font-size: 1em;
  color: white;
  margin: 0.5em;
  border-radius: 0.3em;
  border: 1px solid transparent;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  z-index: 1000; }

.lifeskillSunImage {
  position: absolute;
  width: 3.5em;
  height: 3.5em; }

.lifeskillNum {
  padding-top: 0.1em;
  padding-left: 1.3em;
  padding-right: 1.5em;
  border-radius: 2em;
  margin-right: 1em;
  font-size: 0.9em;
  font-family: "HelveticaNeue", sans-serif;
  color: rgba(0, 0, 0, 0.452);
  z-index: 1; }

.lifeskillName {
  text-align: start;
  font-size: 0.9em;
  font-family: "HelveticaNeue", sans-serif;
  color: white; }

.lifeskillsWorkInProgressTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70em;
  font-size: 0.8em;
  font-family: "HelveticaNeue", sans-serif;
  color: white;
  opacity: 0.3;
  margin-top: 2em; }

.media {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* min-height: 80em; */
  margin-top: 6em;
  width: 70em;
  z-index: 1000; }

.mediaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10em; }

.mediaHeader {
  font-size: 2em;
  color: #D6D4EF;
  font-family: 'Raleway', sans-serif;
  line-height: 1.4em;
  letter-spacing: 0.05em;
  opacity: 0.9; }

.mediaTabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 5em;
  margin-top: 0.4em; }

.mediaThumbnailImage {
  width: 100%;
  height: auto; }

.mediaUsersTab, .mediaCompaniesTab, .mediaMediaTab {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: 1.6em;
  margin: 1em;
  opacity: .4;
  cursor: pointer;
  transition: all .2s ease-in;
  border-radius: 6em;
  user-select: none; }

.mediaUsersTab:hover, .mediaCompaniesTab:hover, .mediaMediaTab:hover {
  transform: translateY(-4px);
  background: #646efc11;
  color: white;
  opacity: 0.7;
  padding: 0.5em 1em;
  letter-spacing: 0.4px; }

.mediaArticlesUsers, .mediaArticlesCompanies, .mediaArticlesMedia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 4em; }

.mediaArticles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1em; }

.mediaArticlesUsersTeaser {
  color: #9eaeee;
  font-family: helvetica;
  letter-spacing: 0.05em;
  font-size: 0.95em;
  line-height: 1.35em;
  text-align: start;
  width: 34em; }

.mediaArticles1 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start; }

.mediaArticles2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: start;
  margin-top: 6em; }

.mediaArticle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: start;
  width: 20em;
  border-radius: 1em;
  padding: 2em;
  padding-bottom: 3em;
  margin: 0.5em;
  transition: all 0.2s ease-in;
  background-color: #1c185c; }

.mediaArticle:hover {
  background-color: #262174;
  cursor: pointer; }

.mediaVideo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: start;
  width: 20em;
  border-radius: 1em;
  padding: 1em;
  margin: 0.5em;
  transition: all 0.2s ease-in;
  background-color: #1c185c; }

.mediaVideo:hover {
  background-color: #262174;
  cursor: pointer; }

.mediaTitle {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  margin: 0;
  margin-top: 1em; }

.mediaText {
  color: white;
  font-family: "HelveticaNeue", sans-serif;
  font-size: 1em;
  opacity: 0.4;
  margin-top: 0.5em;
  line-height: 1.4em; }

.mediaLogoNameContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.2em; }

.mediaLogo {
  width: 1.2em;
  height: 1.2em; }

.mediaName {
  color: white;
  font-family: "HelveticaNeue", sans-serif;
  font-size: 0.6em;
  opacity: 0.4;
  margin-left: 1em; }

.youtube-video-frame {
  width: 40em;
  height: 30em; }

.youtube-video-frame-media {
  width: 100%;
  height: 15em; }

.youtube-video-frame-end {
  width: 30em;
  height: 20em;
  margin-top: 1em; }

.mediaImages {
  padding-top: 2em;
  width: 66em; }

.mediaLogoImage {
  padding: 2em;
  height: 7em;
  width: auto;
  margin: 0.3em;
  background-color: #1b174e62;
  border-radius: 1em; }

.headerMedia {
  color: #D6D4EF;
  font-size: 2.5em;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.02em;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.3em;
  width: 16em;
  z-index: 10000; }

.subheaderMedia {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1.2em;
  letter-spacing: 0.02em;
  font-weight: 500;
  line-height: 1.6em;
  text-align: center;
  width: 42em;
  margin-bottom: 0.5em;
  z-index: 10000; }

.usersFeedbackContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 13em;
  width: 50em; }

.userFeedbackContainer {
  height: 10em;
  width: 20em;
  border: 1px solid aqua; }

.conquerUniverse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5em;
  width: 70em; }

.conquerUniverseContainers {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: row wrap;
  margin-bottom: 1em;
  margin-top: 3em; }

.conquerUniverseContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 21em;
  height: 17em; }

.conquerUniverseimageContainer {
  display: flex;
  align-items: center;
  height: 7em;
  margin-bottom: 1em; }

.conquerUniverseimage {
  height: 20em;
  width: auto; }

.memolifeFamily {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10em;
  margin-bottom: 10em;
  width: 70em;
  color: rgba(255, 255, 255, 0.219); }

.memolifeFamilyMobile {
  display: none; }

.memolifeFamilyHeaderImagesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; }

.memolifeFamilyHeaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
  text-align: center; }

.contactButton {
  color: #D6D4EF;
  letter-spacing: 0.02em;
  font-family: Source Sans Pro;
  background-color: #4452FE;
  width: auto;
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding: 1em 2em 1em 2em;
  font-size: 1em;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all .2s ease-in;
  z-index: 10000; }

.contactButton:hover {
  position: relative;
  transform: translateY(-4px);
  background: #2D3CF5;
  letter-spacing: 0.4px; }

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70em;
  padding-bottom: 3em; }

.footerValueProp {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1em;
  letter-spacing: 0.02em;
  text-align: center;
  margin-bottom: 0;
  width: 22em; }

.footerCompanyName {
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 1em;
  letter-spacing: 0.02em;
  opacity: 0.6;
  margin-bottom: 0; }

.footerLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3em; }

.footerLink {
  padding: 1.5em;
  padding-bottom: 0;
  padding-top: 0;
  color: #A09DAC;
  font-family: Source Sans Pro;
  font-size: 0.95em;
  letter-spacing: 0.02em;
  text-decoration: none; }

.footerIcons {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.footerIcon1 {
  display: flex;
  justify-content: center;
  margin-left: 1.2em;
  margin-right: 1.2em;
  height: 2.5em;
  width: 2.5em;
  border-radius: 2em;
  background-color: #1F1B60;
  padding: 0.6em;
  cursor: pointer; }

.footerIcon2 {
  display: flex;
  justify-content: center;
  margin-left: 1.2em;
  margin-right: 1.2em;
  height: 2.5em;
  width: 2.5em;
  border-radius: 2em;
  background-color: #1F1B60;
  padding: 0.7em;
  cursor: pointer; }

@media only screen and (max-width: 600px) {
  #section-header .mainSection {
    background-color: #0e0c3d;
    position: relative; }
  #section-header .logo {
    padding: 20px 27px; }
  #section-header li a {
    color: #bfbccc;
    font-family: Source Sans Pro;
    font-size: 1.1em;
    letter-spacing: 0.02em; }
  .introDesktop {
    display: none; }
  .introMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 150vw;
    width: 90vw; }
  .canvasMemomanLiftoffContainer {
    height: 92vw; }
  .canvasMemomanLiftoff {
    height: 100vw; }
  .headerButtonIntroContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: center;
    width: 100vw;
    padding-left: 0;
    padding-top: 6vw;
    height: 57vw; }
  .headerIntro1, .headerIntro2 {
    margin: 0;
    font-size: 7vw;
    width: auto;
    text-align: center; }
  .subheaderIntro {
    font-size: 4vw;
    padding: 0;
    width: 90vw;
    text-align: center; }
  .getStartedButton {
    margin-top: 5vw;
    font-size: 3.3vw;
    border-radius: 20vw;
    width: auto;
    padding: 3.5vw 6vw 3.5vw 6vw; }
  .imageIntro {
    position: absolute;
    top: 0;
    right: 0;
    /* width: 180vw; */
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0)); }
  .curvedBackground1 {
    display: none;
    background-color: transparent;
    clip-path: none;
    padding-bottom: 14vw; }
  .introMediaRow {
    justify-content: start;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 95vw;
    margin-bottom: 10vw; }
  ::-webkit-scrollbar-thumb {
    background-color: transparent; }
  .introMediaLogoImage {
    padding: 3.5vw;
    height: 10.5vw;
    width: auto;
    margin: 1vw;
    margin-top: 0;
    margin-left: 0; }
  .missions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    z-index: 1000;
    margin-top: 20vw; }
  .headerMissions {
    font-size: 6vw; }
  .subheaderMissions {
    font-size: 3.7vw; }
  .missionsContainers {
    justify-content: start;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100vw;
    margin: 6vw 0vw;
    margin-left: 6vw;
    padding: 4vw 0vw; }
  .missionsContainers::-webkit-scrollbar {
    display: none; }
  .missionContainer1, .missionContainer2, .missionContainer3 {
    margin: 2vw;
    padding: 5vw;
    padding-left: 7vw;
    width: 80vw;
    border-radius: 2vw; }
  .missionImage {
    height: 45vw;
    margin-top: 5vw;
    margin-bottom: 5vw; }
  .missionTitle {
    font-size: 5vw;
    margin-bottom: 2vw;
    margin-top: 2vw; }
  .missionSubtitle {
    font-size: 3.7vw; }
  .bottomTextMissions {
    width: 90vw; }
  .funBrainhacksContainer {
    margin-top: 20vw; }
  .brainHacksHeader {
    font-size: 7.5vw;
    width: 95vw;
    text-align: center; }
  .brainHackSubheader {
    width: 80vw;
    font-size: 4vw; }
  .brainHacksListContainer {
    flex-direction: column;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 3vw;
    width: 100vw; }
  .brainHackContainer {
    padding: 6vw;
    padding-bottom: 0;
    padding-top: 0;
    width: 47vw; }
  .brainHackImageContainer {
    height: 46vw;
    width: 50vw;
    margin-bottom: 0vw; }
  .brainHackImage {
    width: 80vw;
    height: auto; }
  .brainHackTitle {
    font-size: 4vw;
    width: 42vw;
    font-weight: 600; }
  .brainHackSubtitle {
    font-size: 3.7vw;
    letter-spacing: 0.02em;
    line-height: 1.3em;
    width: 44vw; }
  .universe {
    margin-top: 0;
    width: 90vw; }
  .lifeskillsTitle {
    font-size: 4.2vw;
    margin-bottom: 5vw;
    width: 85vw;
    padding-left: 0; }
  .lifeskillTextContainer {
    width: 80vw;
    margin-bottom: 8vw; }
  .lifeksillText {
    font-size: 4vw;
    padding-left: 4vw; }
  .riveContainer {
    height: 60vw; }
  .canvas {
    width: 125vw;
    height: auto;
    padding-bottom: 15vw;
    margin-right: 0; }
  .popularTabImage {
    display: none; }
  .popularTabImageMobile {
    display: flex;
    width: 92vw;
    height: auto;
    margin-bottom: 8vw; }
  .headerUniverse1, .headerUniverse2 {
    font-size: 7vw;
    margin: 0;
    width: 95vw; }
  .subheaderUniverse {
    font-size: 4vw; }
  .categoryTitlesContainer {
    /* flex-flow: row wrap; */
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    justify-content: start;
    width: 90vw;
    height: 20vw;
    padding-bottom: 3vw; }
  .categoryTitlesContainer::-webkit-scrollbar {
    display: none; }
  .categoryTitle {
    padding: 1.5vw; }
  .galaxyImage {
    padding: 4vw;
    margin: 0vw;
    height: 60vw;
    /* height: 90vw; */ }
  .imageLifeskillNumber {
    padding-top: 0.7vw;
    padding-left: 3vw;
    padding-right: 2vw;
    margin-right: 4vw;
    font-size: 3.2vw; }
  .lifeskillsContainer {
    width: 100vw; }
  .lifeskillsGrid {
    /* height: 70vw; */
    width: 100vw;
    justify-content: center; }
  #categoryPopular, #categoryBrain, #categoryKnowledge, #categoryMind, #categoryBody, #categorySoul, #categoryCareer, #categoryLeisure {
    width: 90vw;
    font-size: 3.7vw;
    margin: 3.2vw;
    padding: 0; }
  .lifeskillPopular, .lifeskillBrain, .lifeskillKnowledge, .lifeskillMind, .lifeskillBody, .lifeskillSoul, .lifeskillCareer, .lifeskillLeisure {
    padding: 1vw 3vw;
    height: 15vw;
    width: 47vw;
    font-size: 3.7vw;
    margin: 1.2vw;
    border-radius: 1vw;
    z-index: 1000; }
  .lifeskillSunImage {
    position: absolute;
    width: 10.5vw;
    height: 13vw; }
  .lifeskillNum {
    padding-top: 0.7vw;
    padding-left: 3.2vw;
    padding-right: 4vw;
    margin-right: 2vw;
    font-size: 3.7vw; }
  .lifeskillName {
    font-size: 3vw; }
  .lifeskillsWorkInProgressTitle {
    width: 90vw;
    font-size: 4vw;
    margin-top: 7vw; }
  .media {
    margin-top: 10vw;
    width: 90vw; }
  .memberProfession {
    font-size: 3.3vw;
    margin-top: 1.5vw; }
  .mediaContainer {
    width: 90vw;
    margin-top: 20vw; }
  .mediaHeader {
    font-size: 6vw;
    text-align: center;
    margin-top: 8vw;
    margin-bottom: 5vw; }
  .subheaderMedia {
    width: 82vw;
    font-size: 4vw;
    padding-bottom: 4vw; }
  .mediaTabs {
    height: 15vw;
    margin-top: 0vw;
    margin-bottom: 0vw; }
  .mediaUsersTab, .mediaCompaniesTab, .mediaMediaTab {
    margin: 5vw;
    font-size: 3.8vw; }
  .mediaArticlesUsers, .mediaArticlesCompanies, .mediaArticlesMedia {
    flex-direction: column;
    margin-bottom: 10vw; }
  .mediaArticlesUsersTeaser {
    width: 80vw;
    font-size: 3.8vw; }
  .mediaArticles2 {
    margin-top: 0; }
  .mediaArticles {
    flex-direction: column;
    margin-top: 0; }
  .mediaArticle {
    width: 80vw;
    border-radius: 1.3vw;
    margin: 1.3vw;
    padding: 4vw; }
  .mediaVideo {
    width: 80vw;
    border-radius: 1.3vw;
    margin: 1.3vw;
    padding: 4vw; }
  .mediaTitle {
    font-size: 4.5vw;
    margin-top: 3vw; }
  .mediaText {
    font-size: 4vw;
    margin-top: 3vw; }
  .mediaLogoNameContainer {
    margin-top: 10vw; }
  .mediaLogo {
    width: 6vw;
    height: 6vw; }
  .mediaName {
    font-size: 4vw;
    margin-left: 4vw; }
  .headerMedia {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7.5vw;
    margin: 0;
    margin-bottom: 6vw;
    width: 90vw; }
  .mediaArticlesContainer {
    flex-direction: column; }
  .mediaArticles2 {
    margin-top: 0; }
  .mediaArticle {
    width: 80vw;
    border-radius: 1.3vw;
    margin: 1.3vw;
    padding: 7vw; }
  .mediaTitle {
    font-size: 4.5vw;
    margin-top: 3vw; }
  .mediaText {
    font-size: 4vw;
    margin-top: 3vw; }
  .mediaLogoNameContainer {
    margin-top: 10vw; }
  .mediaLogo {
    width: 6vw;
    height: 6vw; }
  .mediaName {
    font-size: 4vw;
    margin-left: 4vw; }
  .youtube-video-frame {
    width: 100vw;
    height: 80vw; }
  .youtube-video-frame-media {
    height: 50vw;
    margin-top: 0vw; }
  .youtube-video-frame-end {
    width: 80vw;
    height: 50vw;
    margin-top: 0vw; }
  .mediaImages {
    padding-top: 0vw;
    width: 90vw; }
  .mediaLogoImage {
    padding: 6vw;
    height: 17vw;
    width: auto;
    border-radius: 2vw; }
  .subheaderPackageDeal {
    font-size: 3.8vw;
    width: 90vw;
    text-align: left; }
  .conquerUniverse {
    margin-top: 10vw;
    width: 90vw;
    flex-direction: column;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100vw; }
  .conquerUniverseContainers {
    margin-bottom: 5vw;
    margin-top: 10vw; }
  .conquerUniverseContainer {
    width: 45vw;
    height: 60vw;
    justify-content: start; }
  .conquerUniverseimageContainer {
    height: 28vw;
    margin-bottom: 2vw; }
  .conquerUniverseimage {
    height: 70vw;
    width: auto; }
  .usersFeedbackContainer {
    flex-direction: column;
    margin-top: 7vw;
    height: 130vw;
    width: 90vw; }
  .userFeedbackContainer {
    height: 60vw;
    width: 90vw; }
  .subheaderPodium {
    width: 80vw;
    font-size: 3.8vw;
    padding-bottom: 1.5vw;
    text-align: center; }
  .podiumImageContainer {
    height: 130vw;
    width: 90vw;
    margin-top: 10vw; }
  .podiumImage {
    height: 150vw;
    margin-right: 0em; }
  .contactButton {
    margin-top: 20vw;
    font-size: 3.3vw;
    border-radius: 20vw;
    margin-bottom: 25vw; }
  .memolifeFamily {
    display: none; }
  .memolifeFamilyMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20vw;
    width: 95vw;
    color: rgba(255, 255, 255, 0.219);
    margin-top: 25vw; }
  .memolifeFamilyMobileImage {
    width: 90vw;
    height: auto; }
  .contactButton {
    margin-top: 5vw;
    margin-bottom: 1vw;
    font-size: 3.3vw;
    border-radius: 20vw;
    width: auto;
    padding: 3.5vw 6vw 3.5vw 6vw; }
  .footer {
    width: 100vw;
    padding-bottom: 15vw; }
  .footerValueProp {
    font-size: 3.8vw;
    text-align: center;
    margin-bottom: 0;
    width: 95vw; }
  .footerCompanyName {
    font-size: 3.8vw;
    opacity: 0.6;
    margin-bottom: 0; }
  .footerLinks {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding: 4vw;
    padding-top: 6vw;
    padding-bottom: 6vw;
    width: 100vw; }
  .footerLink {
    padding: 3vw;
    padding-bottom: 2.2vw;
    padding-top: 2.2vw;
    font-size: 3.5vw; }
  .footerIcons {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  .footerIcon1 {
    margin-left: 3vw;
    margin-right: 3vw;
    height: 9vw;
    width: 9vw;
    padding: 2.5vw; }
  .footerIcon2 {
    margin-left: 3vw;
    margin-right: 3vw;
    height: 9.3vw;
    width: 9.3vw;
    padding: 2.9vw; } }

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .introDesktop {
    display: flex;
    flex-direction: column; }
  .headerButtonIntroContainer {
    align-items: center;
    height: 50vw; }
  .headerIntroContainers {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .headerIntro1, .headerIntro2 {
    font-size: 6.7vw;
    text-align: center;
    width: auto; }
  .subheaderIntro {
    font-size: 2.7vw;
    letter-spacing: 0.02em;
    font-weight: 500;
    line-height: 4vw;
    margin-top: 3vw;
    width: 80vw;
    text-align: center; }
  .canvasMemomanLiftoff {
    height: 58vw; }
  .getStartedButton {
    padding: 2vw 4vw 2vw 4vw;
    font-size: 2vw;
    margin-bottom: 7vw; }
  .curvedBackground1 {
    clip-path: ellipse(250% 100% at 50% 0%);
    padding-bottom: 0; }
  .introMediaRow {
    display: flex;
    justify-content: start;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 95vw; }
  ::-webkit-scrollbar-thumb {
    background-color: transparent; }
  .introMediaLogoImage {
    padding: 2vw;
    height: 5.8vw;
    width: auto;
    margin: 1vw;
    margin-top: 1.5vw; }
  .universe {
    margin-top: 8vw;
    width: 90vw; }
  .headerUniverse1, .headerUniverse2 {
    font-size: 5vw;
    width: 80vw; }
  .subheaderUniverse {
    font-size: 2.4vw;
    line-height: 3.6vw;
    margin-top: 2.7vw;
    width: 100%; }
  .riveContainer {
    height: 45vw;
    padding-top: 2vw; }
  .popularTabImage {
    width: 95vw;
    height: auto; }
  .canvas {
    height: 67vw; }
  .categoryTitlesContainer {
    width: 80vw;
    height: 12vw; }
  #categoryPopular, #categoryBrain, #categoryKnowledge, #categoryMind, #categoryBody, #categorySoul, #categoryCareer, #categoryLeisure {
    font-size: 2.2vw;
    line-height: 3vw;
    margin: 2vw; }
  .categoryTitle:hover {
    transform: translateY(-4px);
    background: #646efc11;
    color: white;
    opacity: 0.7;
    padding: 0.5em 1em;
    letter-spacing: 0.4px; }
  .galaxyImageContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    /* height: 23em; */ }
  .galaxyImage {
    padding: 1em;
    /* height: 23em; */
    /* background-color: burlywood; */ }
  .imageLifeskillNumber {
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
    font-size: 2.3em;
    font-family: "HelveticaNeue", sans-serif;
    opacity: 0; }
  .lifeskillsContainer {
    justify-content: center;
    width: 100vw; }
  .lifeskillTextContainer {
    width: 70vw;
    margin-bottom: 4vw; }
  .lifeksillText {
    font-size: 2.6vw;
    width: 50vw;
    margin-left: 10vw; }
  .lifeskillsTitle {
    width: 95vw;
    margin-bottom: 3vw;
    padding-left: 1.5vw;
    font-size: 2.6vw; }
  .lifeskillsGrid {
    justify-content: start;
    width: 95vw; }
  .lifeskillPopular, .lifeskillBrain, .lifeskillKnowledge, .lifeskillMind, .lifeskillBody, .lifeskillSoul, .lifeskillCareer, .lifeskillLeisure {
    padding: 1vw 2vw;
    height: 9vw;
    width: 29.6vw;
    font-size: 2vw;
    margin: 1vw; }
  .lifeskillSunImage {
    width: 7vw;
    height: 7vw; }
  .lifeskillNum {
    padding-top: 0.3vw;
    padding-left: 2.4vw;
    padding-right: 2vw;
    border-radius: 6vw;
    margin-right: 2vw;
    font-size: 1.9vw; }
  .lifeskillName {
    font-size: 1.8vw; }
  .funBrainhacksContainer {
    margin-top: 15vw;
    width: 100vw; }
  .brainHacksHeader {
    font-size: 5vw; }
  .brainHackSubheader {
    font-size: 2.4vw;
    line-height: 3.6vw;
    margin-top: 2.7vw;
    width: 70vw; }
  .brainHacksListContainer {
    flex-flow: wrap;
    margin-top: 5vw; }
  .brainHackContainer {
    padding: 3vw;
    width: 50vw; }
  .brainHackImageContainer {
    height: 20vw;
    width: 40vw;
    margin-bottom: 4vw; }
  .brainHackImage {
    width: 44vw;
    height: auto; }
  .brainHackTitle {
    font-size: 3vw; }
  .brainHackSubtitle {
    font-size: 2vw;
    margin-top: 12px;
    width: 40vw; }
  .missions {
    margin-top: 15vw;
    width: 95vw; }
  .missionsContainers {
    flex-flow: wrap;
    width: 92vw; }
  .missionContainer1, .missionContainer2, .missionContainer3 {
    margin: 2vw;
    margin-bottom: 2vw;
    margin-left: 1vw;
    margin-right: 1vw;
    padding: 3vw;
    padding-top: 2vw;
    width: 43vw;
    border-radius: 1vw; }
  .missionContainer3 {
    margin-right: 1vw; }
  .missionImage {
    height: 24vw;
    margin-top: 4vw;
    margin-bottom: 3vw; }
  .missionTitle {
    font-size: 3vw; }
  .missionSubtitle {
    font-size: 2vw; }
  .mediaContainer {
    margin-top: 15vw; }
  .headerMedia {
    font-size: 5vw;
    width: 80vw; }
  .mediaUsersTab {
    font-size: 2.4vw;
    line-height: 3.5vw;
    margin: 2vw; }
  .mediaMediaTab {
    font-size: 2.4vw;
    line-height: 3.5vw;
    margin: 2vw; }
  .mediaCompaniesTab {
    font-size: 2.4vw;
    line-height: 3.5vw;
    margin: 2vw; }
  .subheaderMedia {
    font-size: 2.4vw;
    line-height: 3.5vw;
    width: 90vw;
    margin-bottom: 1.1vw; }
  .mediaImages {
    padding-top: 4vw;
    width: 85vw; }
  .mediaArticle {
    width: 45vw;
    height: 40vw;
    border-radius: 2vw;
    padding: 4vw;
    padding-bottom: 5vw;
    margin: 1vw; }
  .mediaTitle {
    font-size: 2.2vw;
    margin-top: 2vw; }
  .mediaVideo {
    width: 45vw;
    border-radius: 1vw;
    padding: 2vw;
    margin: 1vw; }
  .mediaLogoImage {
    padding: 3.4vw;
    height: 11.5vw;
    width: auto;
    margin: 0.4vw;
    border-radius: 1.5vw; }
  .conquerUniverse {
    margin-top: 5vw;
    width: 95vw; }
  .conquerUniverseContainers {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: row wrap;
    margin-bottom: 1em;
    margin-top: 3em; }
  .conquerUniverseContainer {
    width: 45vw;
    height: 34vw; }
  .conquerUniverseimageContainer {
    height: 16vw;
    margin-bottom: 2vw; }
  .conquerUniverseimage {
    height: 50vw;
    width: auto; }
  .memolifeFamily {
    display: none; }
  .memolifeFamilyMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.219);
    margin-top: 15vw;
    margin-bottom: 25vw;
    width: 95vw; }
  .memolifeFamilyMobileImage {
    width: 80vw;
    height: auto; }
  .contactButton {
    padding: 2vw 4vw 2vw 4vw;
    font-size: 2vw;
    margin-top: 4vw;
    margin-bottom: 1vw; } }
