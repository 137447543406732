.pushdownMenu {
  &.expanded {
    max-height: 380px;
  }


  clear: both;
  max-height: 0;
  transition: max-height .5s ease-out;
  background-color: $backgroundColor;
  overflow: hidden;
  top: $headerHight;
  width: 100%;

  color: mix(white, $menuBackgroundColor, 80%);

  .menu-content {
    display: flex;
    background-color: $menuBackgroundColor;
    flex-direction: column;
    align-items: center;
    padding: 10px calc(10% - 10px);

    .item-with-atag {
      padding: 0.6em;
      &:hover {
        color: white;
      }
    }
  }

  li.divider {
    height: 1px;
    margin: 0.4em 0;
    background-color: mix(white, $menuBackgroundColor, 5%);
    width: 100%;
    max-width: 400px;
  }


  li[data-lang] {
    a {
      padding: 10px;
      padding-left: 45px;
    }

    &:hover {
      color: white;
    }

    &.active {
      color: white;
      a {
        &::after {
          content: "";
          display: inline-block;
          background-image: hashed-url("images/_shared/checkmark.svg");
          background-size: 100%;
          background-position: center;
          height: 12px;
          width: 12px;
          margin-left: 6px;
        }
      }
    }

  }
}