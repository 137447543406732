[data-carousel] {
  $carouselSideMargin: 40px;
  position: relative;

  ul {
    display: flex;
    width: (100% / 3);
    transition: transform 1s;
    transform: translate3d(0, 0, 0);
  }

  li {
    transition: transform 1s;
    transform: translate3d(0, 0, 0);

    width: 100%;
    min-width: 100%;
  }

  @for $i from 1 through 6 {
    &[data-carousel-size="#{$i}"] {
      ul {
        width: percentage((1 / $i));
      }
    }
  }
  &[data-carousel-size="2"] {
    .slides-container {
      width: 24em;
    }
  }

  &[data-carousel-prev="0"] .prev,
  &[data-carousel-next="0"] .next {
    background-color: #7fb7db;
    cursor: default;
  }

  &[data-carousel-prev="0"][data-carousel-next="0"] {
    .prev, .next {
      display: none;
    }
  }




  .prev,
  .next {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    margin-top: -15px;
    background-color: #1c80c6;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 2;

    &::after {
      $expand: 10px;
      content: "";
      display: block;
      position: absolute;
      top: - $expand;
      right: - $expand;
      bottom: - $expand;
      left: - $expand;

    }
  }
  $navigButtonBorderRadius: 2px;
  .prev {
    left: 0px;
    border-radius: 0 $navigButtonBorderRadius $navigButtonBorderRadius 0;
    background-image: hashed-url("images/_shared/icon-chevron-left.svg");
    background-position: left center; // Extra adjustment due to bad svg
    &::after {
      left: 0px;
    }
  }
  .next {
    right: 0px;
    border-radius: $navigButtonBorderRadius 0 0 $navigButtonBorderRadius;
    background-image: hashed-url("images/_shared/icon-chevron-right.svg");
    &::after {
      right: 0px;
    }
  }



  .slides-container {
    position: relative;
    width: 100%; // can be less
    max-width: 100%;
    margin: auto;
    overflow: hidden;
    padding: 0px $carouselSideMargin;


    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: $carouselSideMargin;
      z-index: 1;
    }
    &::before {
      left: 0px;
      background: linear-gradient(to right, $lightBackgroundColor 0%, rgba($lightBackgroundColor,0) 100%);
    }
    &::after {
      right: 0px;
      background: linear-gradient(to left, $lightBackgroundColor 0%, rgba($lightBackgroundColor,0) 100%);
    }
  }

  @for $i from 1 through 4 {
    &[data-carousel-prev="#{$i}"] {
      ul {
        transform: translateX((-100% * $i)) translate3d(0, 0, 0);
      }
      li:nth-child(#{$i}) {
        transform: translateX(- $carouselSideMargin) translate3d(0, 0, 0);
      }
    }
    &[data-carousel-next="#{$i}"] {
      li:nth-last-child(#{$i}) {
        transform: translateX($carouselSideMargin) translate3d(0, 0, 0);
      }
    }
  }
}