$mobileBreakpoint: 500px;
$largescreenBreakpoint: 768px;
$maxContentWidth: 1280px;

$greenColor: #63CB6C;//#31cd64;

// Background colors for the sections
$whiteBackgroundColor: white;
$lightBackgroundColor: #f6f6f6;
$darkBackgroundColor: #191A37;

$whitebgTextColorA: #292b32;//black;
$whitebgTextColorB: #4b4c56;//#787E86;
$whitebgTextColorC: #9597a5;

$darkbgTextColorA: white;
$darkbgTextColorB: #DADAEA;
$darkbgTextColorC: #92929f;

$sectionTopPadding: 6%;//40px;//10%;
$sectionBottomPadding: 40px;//10%;
$sectionVerticalGap: 40px;//10%;
$sectionSidePadding: 10%;

//$section-topPadding: 6%;
$section-topPadding: calc(#{40px - ($mobileBreakpoint / 100 * 5)} + 5%); // Ensure that the value amouts to 40px at the mobileBreakpoint
$section-verticalGap: 40px;
$section-bottomPadding: 40px;

$sectionBody-topPadding: 6%;//40px;
$sectionBody-bottomPadding: 40px;




$lifeAreaColors: (
  brain: #18af58,
  knowledge: #9885c2,
  mind: #d94d36,
  body: #e48831,
  soul: #e8b730,
  career: #2d7392,
  leisure: #732985,
);


$paragraphFontSize: 14px;

// Perspective matrixes created with /matrix3d-editor/index.html
$perspective_ipad:  matrix3d(0.5368321124916735, -0.22876889085744948, 0, 0.00009613116164606617, 0.45131219647239, 0.18929687989273694, 0, -0.00009885552655101541, 0, 0, 1, 0, 72, 276, 0, 1);
$perspective_iphoneA: matrix3d(0.5411877044438375,	0.14279508194958504,	0,	0.00009462752241489437,0,	0.6985018726591761,	0,	0,0,	0,	1,	0,175,	212,	0,	1);
$perspective_iphoneB: matrix3d(0.43442662543594446,	-0.13326432066330735,	0,	-0.00008996996840513345,0.0007806334036477391,	0.6448763757946246,	0,	0.000001434987874352417,0,	0,	1,	0,108,	315,	0,	1);

@import "_include-media";

html {
  box-sizing: border-box;
}

*, *:before, *:after { box-sizing: inherit; }

body {
  position: relative; // Needed for the header
  margin: 0px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;

  // Relevant for wide screens
  background-color: #D0D0D0;
  max-width: $maxContentWidth;
  margin: auto;
}

// Reset ul style
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}



section {
  &.has-standard-header {
    & > .header {
      display: flex;
      flex-direction: column;
      align-items: center;

      text-align: center;
      padding: $section-topPadding $sectionSidePadding 0px $sectionSidePadding;
    }
  }

  &.has-standard-body {
    & > .body {
      padding: $section-topPadding $sectionSidePadding $section-bottomPadding $sectionSidePadding;
    }
  }

  &.has-standard-header.has-standard-body {
    & > .body {
      padding-top: $section-verticalGap;
    }
  }

  & > .header {
    .title {
      font-size: 140%;
      font-weight: bold;
      max-width: 600px;
      width: 100%; // Fixing ie11 bug
    }
    .subtitle {
      margin-top: 1em;
      max-width: 600px;
      width: 100%; // Fixing ie11 bug
    }
  }

  &.darkbg {
    background-color: $darkBackgroundColor;
    color: $darkbgTextColorB;

    & > .header {
      .title {
        color: $darkbgTextColorA;
        font-weight: normal; // Because white font on dark bg looks bolder
      }
      .subtitle {
        font-weight: 300;
      }
    }
  }

  &.whitebg {
    background-color: $whiteBackgroundColor;
    color: $whitebgTextColorB;

    & > .header {
      .title {
        color: $whitebgTextColorA
      }
    }
  }

  &.lightbg {
    background-color: $lightBackgroundColor;
    color: $whitebgTextColorB;

    & > .header {
      .title {
        color: $whitebgTextColorA
      }
    }

  }
}

// TODO maybe need a banner section in pricing too?
#section-banner, .xsectionBanner { // Shared styling for all banner sections
  // Desktop
  @media (min-width: $mobileBreakpoint + 1px) {
    & > .header, .xbannerHeader {
      .title {
        font-size: 5vw;
      }
      .subtitle {
        font-size: 3vw;
      }
    }
  }


  // Controlling max font size
  $breakpoint-32px: 640px;
  @media (min-width: $breakpoint-32px) {
    & > .header, .bannerHeader {
      .title {
        font-size: ($breakpoint-32px / 100 * 5); // 32px
      }
      .subtitle {
        font-size: ($breakpoint-32px / 100 * 3);
      }
    }
  }
}

@import "_mixins";

@import "_product-icon";

@import "_section-header";

@import "_section-footer";

@import "_zendeskbutton";

@import "_carousel";

@import "_video-player";


// Mobile
@media (max-width: $mobileBreakpoint) {
  body {
    font-size: 14px;
  }
}

// Desktop
@media (min-width: $mobileBreakpoint + 1px) {
}
