@mixin standard-green-button($showPlayButton: false) {
  display: inline-block;
  background-color: $greenColor;
  //border: 1px solid darken($greenColor, 15%);
  border-radius: 2px;
  color: white;

  padding: 10px 20px; // reset this if button contains no a-tag
  a {
    padding: 10px 20px;
  }
  cursor: pointer;

  &:hover {
    background-color: darken($greenColor, 5%);
  }
  &:active {
    background-color: darken($greenColor, 10%);
  }


  @if $showPlayButton {
    &::after {
      content: "";
      display: inline-block;
      position: relative;
      width: 1em;
      height: 1em;
      top: 0.2em; // Adjustment
      margin-left: 0.5em;
      background-image: hashed-url("images/_shared/icon-play-rounded-white.svg");
      background-position: center;
      background-size: 90%;
      background-repeat: no-repeat;
    }
  }
}


@mixin standard-flexwrap-ul() {
  // Mobile
  @media (max-width: $mobileBreakpoint) {
    margin-bottom: -1.5em;
    li {
      margin-bottom: 1.5em;
    }
  }
  // Desktop
  @media (min-width: $mobileBreakpoint + 1px) {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -20px;
    li {
      width: percentage(1/2);
      padding: 10px 20px;
    }
  }
  // Large (inherits Desktop)
  @media (min-width: $largescreenBreakpoint + 1px) {
    li {
      width: percentage(1/3);
    }
  }
}