.zendeskbutton {
  $zendeskbuttonSize: 40px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  background-color: $greenColor;
  color: white;
  z-index: 2;

  font-weight: 600;
  bottom: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);

  &::before {
    position: absolute;
    left: 0px;
    content: "";
    display: block;
    width: $zendeskbuttonSize;
    height: $zendeskbuttonSize;
    border-radius: 50%;


    background-image: hashed-url("images/_shared/icon-envelope.svg");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
  }


  // Mobile
  @media (max-width: $mobileBreakpoint) {

    height: $zendeskbuttonSize;
    width: $zendeskbuttonSize;
    right: 10px;
    bottom: 10px;

    border-radius: 50%;
    .label {
      display: none;
    }

  }

  // Desktop
  @media (min-width: $mobileBreakpoint + 1px) {

    $padding: 3px;
    border-radius: ($zendeskbuttonSize / 2 + $padding) 0px 0px ($zendeskbuttonSize / 2 + $padding);
    padding-left: $zendeskbuttonSize + $padding + 10px;
    padding-right: 10px;
    right: 0px;

    height: $zendeskbuttonSize + $padding * 2;
    line-height: $zendeskbuttonSize + $padding * 2;

    background-color: darken($greenColor, 10%);

    &::before {
      background-color: $greenColor;
      top: $padding;
      left: $padding;
    }

  }
}


