#section-header {
  $headerHight: 80px;
  $backgroundColor: white;
  $menuBackgroundColor: #2c2d42; // Variation of the $darkBackgroundColor;
  $breakpointA: 600px;
  $breakpointB: 960px;

  background-color: $backgroundColor;

  @import '_section-header-productbox-dropdown';
  @import '_section-header-pushdown-menu';

  .mainSection {
    display: flex;
    align-items: center;
    height: $headerHight;

    .school, .business, .products, .pricing, .login, .about, .workHappiness {
      color: gray;
      &:hover {
        color: black;
      }
    }

    .school, .business, .about, .products, .pricing a, .login a, .workHappiness {
      padding: 1em 0px;
      margin: 0px 0.4em;
    }


    .signup { // Only visible in wide window
      margin-right: 40px;
      margin-left: 0.8em;

      background-color: $greenColor;
      border-radius: 2px;
      color: white;

      a {
        padding: 0.6em 0.8em;
        display: block;
      }
    }

    .products { // Special treatment, because this one does not have an a-tag within
      position: relative;
      cursor: pointer;

      &.active {
        color: black;
        &:before {
          content: "";
          bottom: 0px;
          width: 100%;
          display: block;
          height: 1px;
          background-color: black;
          position: absolute;
        }
      }
    }


    .languagemenu-icon {
      display: block;
      padding: 10px;
      padding-left: 35px;
      cursor: pointer;


      &::after {
        content: "";
        display: inline-block;
	      width: 0;
	      height: 0;
	      border-left: 3px solid transparent;
	      border-right: 3px solid transparent;
	      border-top: 5px solid black;
        margin-left: 7px;

        position: relative;
        top: -2px;
      }
    }

    .languagemenu-btn {
      display: none;

      &:checked ~ .languagemenu {
        display: block;
      }
    }
    .languagemenu {
      position: absolute;
      background-color: white;
      display: none;
      margin-top: 10px;
      z-index: 2;

      [data-lang] a {
        padding: 10px 0px;
        padding-left: 45px;
      }
    }

  }

  .flex-spacer {
    flex: 1;
  }

  .logo {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    padding-right: 0px;
    margin-right: 0.4em;

    &::before {
      display: inline-block;
      content: "";
      width: 2.5em;
      height: 2.5em;
      background-image: hashed-url("images/_shared/logo.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 0.5em;
    }
    &::after {
      $height: 1.2em;
      display: inline-block;
      content: "";
      width: $height * 6.5;
      height: $height;
      margin-bottom: 0.2em; // Align better to next menu item
      background-image: hashed-url("images/_shared/memolife.svg");
      background-size: auto 100%;
      //background-position: center;
      background-repeat: no-repeat;
    }
  }

  #pushdownmenu-btn {
    $color: #333;
    align-self: stretch;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-right: 27px;
    padding-left: 17px;

    padding-top: 2px; // Better alignment with other items

    cursor: pointer;

    position: relative;
    user-select: none;

    // Preventing gray highlight when tapping menu icon
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    &::after {
      content: "";
      display: block;
      width: 100px;
      position: absolute;
      height: 30px;
      right: 16px;
      bottom: 0px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 14px solid $menuBackgroundColor;

      border-bottom-width: 0px;
      transition: border-bottom-width 0.5s;
    }

    .navicon {
      background: $color;
      display: block;
      height: 2px;
      position: relative;
      transition: background .5s ease-out;
      width: 18px;
    }

    .navicon:before,
    .navicon:after {
      background: $color;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .5s ease-out;
      width: 100%;
    }

    .navicon:before {
      top: 5px;
    }

    .navicon:after {
      top: -5px;
    }

    &.active {
      .menu {
        max-height: 340px;
      }

      &::after {
        border-bottom-width: 14px; // Animates funnel
      }

      .navicon {
        background: transparent;
      }

      .navicon::before {
        top: 0px;
        transform: rotate(-45deg);
      }

      .navicon::after {
        top: 0px;
        transform: rotate(45deg);
      }
    }
  }

  li a {
    display: block;
  }
  a {
    text-decoration: none;
    color: inherit;
  }


  [data-lang] {
    background-size: 20px;
    background-position: 15px center;
    background-repeat: no-repeat;

    a {
      padding-left: 45px;
    }

  }
  [data-lang="EN"] {
    background-image: hashed-url("images/_shared/lang-icons/us.svg");
  }
  [data-lang="NO"] {
    background-image: hashed-url("images/_shared/lang-icons/no.svg");
  }

  li a {
    display: block;
    text-decoration: none;
    color: inherit;
  }

  li.active {
    a {
      &::after {
        content: "";
        display: inline-block;
        background-image: hashed-url("images/_shared/checkmark-black.svg");
        background-size: 100%;
        background-position: center;
        height: 12px;
        width: 12px;
        margin-left: 6px;
      }
    }
  }


  .blockingLayer {
    position: fixed;
    top: $headerHight;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    display: none;
  }

  // -----------------------------------------
  // Styling for dark bg
  // -----------------------------------------

  &.darkbg {
    background-color: #233c7c;
    .logo {
      &::after {
        background-image: hashed-url("images/_shared/memolife-white.svg");
      }
    }


    .mainSection {
      .school, .business, .products, .pricing, .login, .about, .workHappiness {
        color: rgba(white, 0.8);
        &:hover {
          color: white;
        }
      }
      .languagemenu {
        background-color: transparent;
        li:hover {
          color: white;
        }

        li.active {
          color: white;
          a {
            &::after {
              background-image: hashed-url("images/_shared/checkmark.svg");
            }
          }
        }
      }
      .languagemenu-icon::after {
        border-top: 5px solid rgba(white, 0.8);
      }
      .languagemenu-icon:hover::after {
        border-top: 5px solid white;
      }
    }

    #pushdownmenu-btn {
      $color: white;
      $menuBackgroundColor: white;//#233c7c;

      &::after {
        // border-bottom: 14px solid $menuBackgroundColor;
        border-bottom-color: $menuBackgroundColor;
      }

      &:not(.active) {
        .navicon {
          background: $color;
        }
      }

      .navicon:before,
      .navicon:after {
        background: $color;
      }
    }

    .pushdownMenu {
      color: rgba(black, 0.8);

      li.divider {
        background-color: rgba(black, 0.05);
      }

      li[data-lang].active {
        color: black;

        a::after {
          background-image: hashed-url("images/_shared/checkmark-black.svg");
        }
      }
      li[data-lang]:hover {
        color: black;
      }
    }
    .menu-content {
      background-color: white;

      .item-with-atag {
        // color: black;
      }
      .item-with-atag:hover {
        color: black;
      }
    }
  }


  // -----------------------------------------
  //
  // -----------------------------------------

  // Small
  @media (max-width: $breakpointA) {
    position: relative;
    z-index: 1;

    .mainSection {
      .school, .business, .languages, .about, .products, .pricing, .login, .signup, .workHappiness {
        display: none;
      }
    }

    .productbox-container.expanded {
      display: none;
    }
  }

  // Medium
  @media (min-width: $breakpointA + 1) and (max-width: $breakpointB) {
    .mainSection {
      .school, .business, .languages, .about, .signup {
        display: none;
      }
    }

    .pushdownMenu {
      .pricing, .login {
        display: none;
      }
    }
  }

  // Large
  @media (min-width: $breakpointB + 1px) {
    .mainSection {
      #pushdownmenu-btn {
        display: none;
      }
    }
    .productbox {
      margin-right: 40px;
    }
    .pushdownMenu {
      display: none;
    }
  }
}
